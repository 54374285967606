<template>
  <div id="div">
    <el-row>
      <el-col :span="12" :offset="6">
        <el-input
          :placeholder="placeholder"
          v-model="input"
          class="input-with-select"
        >
          <el-select v-model="select" slot="prepend" placeholder="用户类型" >
            <el-option label="自然人" value="1"></el-option>
            <el-option label="企业" value="2"></el-option>
          </el-select>
          <el-button
            slot="append"
            @click="eliminate"
            icon="el-icon-delete"
             v-prevent-click
          >清除数据</el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-row>

         <el-link id="linkaddr" :href="eqbaddr" target="_blank">e签宝SDK数据清除地址</el-link>
    </el-row>
  </div>
</template>

<script>
export default {
  computed:{
    placeholder(){
      if(this.select==1){
        return "请输入自然人身份证号";
      }else if(this.select==2){
        return "请输入企业信用代码";
      }else{
        return "请先选择用户类型";
      }
       
    }
  },
  data() {
    return {
      input: "",
      select: "",
      eqbaddr: this.eqbaddr
    };
  },
  methods: {
    eliminate() {
      if(this.idno().test(this.input)==false){
        if(this.select==1){
this.$message.error('身份证');
        }
        if(this.select==2){
this.$message.error('身份');
        }
        return ;
      }

      this.api.platformApi
        .eliminate({ type: this.select, idno: this.input })
        .then((res) => {
          if (res.data) {
            this.$message({
              message: res.msg,
              type: "success",
            });
          } else {
             this.$message.error(res.msg); 
          }
        });
    },
    idno(){
        return /(^[0-9|a-z|A-Z]{15}$)|(^[0-9|a-z|A-Z]{18}$)/
    }
  },
};
</script>

<style scoped>
#div {
  margin-top: 300px;
}
#linkaddr {
   margin: 50px;
    font-size: 20px;
}

>>> .el-select .el-input {
  width: 130px;
}
>>> .input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>