<template>
  <div id="loanProcessingDetails">
    <div>
      <div class="textTips">
        <el-row>
          <el-col :span="4" align="left">
            <span>基本信息</span>
          </el-col>
        </el-row>
      </div>
      <table id="table">
        <tr>
          <td>作业单编号</td>
          <td style="width:10%">{{ task.thirdParty }}</td>
          <td>供应方名称</td>
          <td style="width:10%">{{ task.taskorName }}</td>
          <td>客户单号</td>
          <td style="width:10%">{{ task.clientBillNo }}</td>
          <td>金额</td>
          <td style="width:10%">{{accDiv(task.amount , 100)}}</td>
        </tr>
        <tr>
          <td>订单状态</td>
          <td>
            <div
                v-for="(item, index) in taskState"
                v-bind:key="index"
              >
                <div v-if="item.datavalue == task.state">
                  {{ item.name }}
                </div>
              </div>
          </td>
          <td>订单类型</td>
          <td>
            <div
                v-for="(item, index) in taskType"
                v-bind:key="index"
              >
                <div v-if="item.datavalue == task.type">
                  {{ item.name }}
                </div>
              </div>  
          </td>
          <td>预计结束时间</td>
          <td>{{ task.expectedEndTime }}</td>
          <td>录入时间</td>
          <td>{{ task.createTime }}</td>
        </tr>
        <tr>
          <td>创建时间</td>
          <td>{{ task.bzCreateTime }}</td>
        </tr>
      </table>
    </div>

    <el-divider class="el-divider"></el-divider>
    <logistics :formData="task.contentData" ref="logistics"></logistics>

  </div>
</template>
<script>

import logistics from './logistics'
export default {
  components:{
    logistics,
  },
  data() {
    return {
      task:{},
      taskState:[],
      stateDiction: {
        code: "taskState",
      },
      subjectType:[],
      subjectDiction: {
        code: "subjectType",
      },
      taskType:[],
      typeDiction: {
        code: "taskType",
      },
      transportMode:[],
      modeDiction:{
        code:'transportMode'
      },
      model:[],
      modelDiction:{
        code:"model"
      },
      carriageTemperature:[],
      temperatureDiction:{
        code:'carriageTemperature'
      },
      transportationStatus:[],
      transportationStatusDiction:{
        code:"transportationStatus"
      },
      contentData:[]
    };
   
  },
  created(){
    this.task = this.$route.query.task
  },
  async mounted() {
    this.loadState()
  },
  methods: {
    loadState(){
      this.api.platformApi
        .findDictionariesItemList(this.stateDiction)
        .then((res) => {
          this.taskState = res.data.data;
        })
      this.api.platformApi.findDictionariesItemList(this.subjectDiction)
        .then((res)=>{
          this.subjectType = res.data.data;
        })
      this.api.platformApi.findDictionariesItemList(this.typeDiction)
        .then((res)=>{
          this.taskType = res.data.data;
        })
        this.api.platformApi.findDictionariesItemList(this.modeDiction)
        .then((res)=>{
          this.transportMode = res.data.data;
        })
        this.api.platformApi.findDictionariesItemList(this.modelDiction)
        .then((res)=>{
          this.model = res.data.data;
        })
        this.api.platformApi.findDictionariesItemList(this.temperatureDiction)
        .then((res)=>{
          this.carriageTemperature = res.data.data;
        })
        this.api.platformApi.findDictionariesItemList(this.transportationStatusDiction)
        .then((res)=>{
          this.transportationStatus = res.data.data;
        })
    },
    dictionary(arr,num){
      if(arr == null || arr[num] == null){
        return '';
      }
      return arr[num].name;
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0;overflow: visible;";
    },
  },
};
</script>
<style scoped>
#loanProcessingDetails {
  background-color: white;
  padding: 15px 0 100px 30px;
  text-align: left;
}
#loanProcessingDetails table,
td {
  border: 1px solid #e8e8e8;
  border-collapse: collapse;
  line-height: 35px;
  margin-top: 3px;
}
table {
  width: 90%;
}
td:nth-child(odd) {
  background: #fafafa;
  width: 13%;
  text-align: center;
  font-size: 13px;
}
td:nth-child(even) {
  text-align: left;
  color: #7c7c7c;
  font-size: 13px;
}
#personTable {
  width: 90%;
  margin-top: 15px;
  background-color: rgba(29, 144, 255, 0);
}
#loanProcessingDetails span {
  font-size: 15px;
}
#loanProcessingDetails .marginCss {
  margin-top: 35px;
}
#loanProcessingDetails .leftCss {
  margin-right: 27px;
}
#loanProcessingDetails .buttonCss {
  border-radius: 5px;
}
.buttonCss button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
#loanProcessingDetails .fuInfo {
  margin-top: 5px;
}
.padding_class {
  margin-top: 15px;
}
>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  /* padding-left: 0;
  padding-right: 0; */
}
>>> .el-table__body-wrapper{
  overflow: visible;
}
.main_class {
  /* border: solid;
  margin: 0 auto; */
  padding-top: 5px;
}
.margin_right {
  margin-right: 10px;
}
.dialogBox {
  text-align: left;
}

.overflow{
  overflow: visible;
}
.payDiv{
  position: absolute;
  margin-left: 35px;
  background-color: #FFF;
  z-index: 9;
  visibility: hidden;
  display: none;
}
.payFreight,.payFreight:hover{
  color: #BFBF00;
}
.payFreight:hover+.payDiv,.payDiv:hover{
  visibility: visible;
  display: block;
}


.textTips {
  margin: 20px 0 0 0;
}
.el-divider {
  margin: 10px 0 20px 0;
}
.pingji_seting {
  text-align: center;
}
</style>