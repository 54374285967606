<template>
  <div class="search">
    <template>
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-row :gutter="24">
          <el-col :span="10" align="left">
            <div class="grid-content bg-purple">
              <el-form-item label="交易时间段:">
                <el-date-picker
                  v-model="formInline.quyStDt"
                  type="date"
                  placeholder="起"
                  editable
                  pattern="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                ></el-date-picker
                >-
                <el-date-picker
                  v-model="formInline.quyEndDt"
                  type="date"
                  placeholder="止"
                  editable
                  pattern="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </div>
          </el-col>
          <div>
            <el-form-item class="buttonbox">
              <el-button
                type="primary"
                @click="query"
                icon="el-icon-search"
                >查询</el-button
              >
              <el-button
                type="primary"
                @click="rest"
                icon="el-icon-refresh-right"
                >重置</el-button
              >
              <el-button
                icon="el-icon-upload2"
                type="primary"
                @click="downloadsExcelDownTransfer()"
                :loading="flag1"
                >导出</el-button
              >
              <el-button
                icon="el-icon-upload2"
                type="primary"
                @click="downloads()"
                :loading="flag2"
                >批量下载</el-button
              >
            </el-form-item>
          </div>
        </el-row>
      </el-form>
    </template>

    <el-table
      id="table"
      :data="list"
      :header-cell-style="tableHeader"
      :cell-style="tableCell"
      :default-sort="{ prop: 'timestamp', order: 'descending' }"
      border
      v-loading="loading"
      element-loading-text="数据拼命加载中..."
      style="width: 100%"
      row-key="ptnsrl"
      ref="multipleTable"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55"
        align="center"
        :reserve-selection="true"
      >
        <!-- :selectable="selectInit" -->
      </el-table-column>
      <el-table-column prop="id" align="center" width="50" label="序号">
        <template slot-scope="scope">
          <span>{{
            scope.$index + 1 + (page.startNum - 1) * page.pageSize
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="aclamt"
        label="交易金额"
        :show-overflow-tooltip="true"
        align="center"
      >
        <template slot-scope="scope">
			<span v-if="scope.row.tye == 'D'">
				+
			</span>
			<span v-if="scope.row.tye == 'C'">
				-
			</span>
          {{ scope.row.aclamt / 100 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="balamt"
        label="余额"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="ptnsrl"
        label="ptnsrl"
        v-if="show"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="feeamt"
        label="手续费"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="accountnm"
        label="收付款方"
        :show-overflow-tooltip="true"
        align="center"
      />
      <el-table-column
        prop="usage"
        label="交易说明"
        :show-overflow-tooltip="true"
        align="center"
      />
	  <el-table-column
	    prop="tye"
	    label="业务类型(D:借,C:贷)"
	    :show-overflow-tooltip="true"
	    align="center"
	  />
      <el-table-column
        prop="times"
        label="交易时间"
        :show-overflow-tooltip="true"
        align="center"
      >
      </el-table-column>
      <el-table-column label="电子回单" width="100" align="center">
        <template slot-scope="scope">
          <el-col :span="22">
            <div class="grid-content bg-purple">
              <el-link
                type="primary"
                icon="el-icon-fetchByIdSysUserList"
                @click="userdetails(scope.row)"
                :underline="false"
                size="medium"
                >下载</el-link
              >
            </div>
          </el-col>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      id="page"
      background
      @size-change="handleSizeChange"
      @current-change="currentChange"
      :current-page.sync="page.startNum"
      :page-sizes="[5, 10, 20]"
      :page-size="page.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { findDictionariesItemList } from "@/service/moudules/platformApi";
export default {
  props: ["ruleForm"],
  data() {
    return {
      flag2:false,
      flag1:false,
      loading: false,
      formInline: {
        quyStDt: null,
        quyEndDt: null,
        subNo: null,
        ptnsrl: null,
        channel:null,
      },
      page: {
        startNum: 1,
        pageSize: 10,
      },
      diction: {
        code: "transferType",
      },
      total: 0,
      list: [],
      itemList: [],
      showDatas: [],
      show: false,
      downloadList: [],
    };
  },

  mounted: function () {
    //this.transaction();
  },

  methods: {
    async downloads() {
      this.flag2=true;
		  this.downloadList = [];
      if (this.$refs.multipleTable.selection.length == 0) {
        this.$message({
          message: "请勾选再进行批量下载",
          type: "error",
        });
         this.flag2=false;
        return;
      }
      for (var i = 0; i < this.$refs.multipleTable.selection.length; i++) {
        var params = {
          subNo: this.$refs.multipleTable.selection[i].subno,
          ptnSrl: this.$refs.multipleTable.selection[i].ptnsrl,
          channel: this.$route.params.channel,
        };
        console.log("================", params);
		let response = await this.api.platformApi.printTransferVoucher(params);
        this.downloadList[i] = response.data.data.pdffile;
        console.log("=============已获取的url:" + response.data.data.pdffile);
	  }
	  console.log("==================downloadList数组:",this.downloadList);
	   var params = {
          urls:this.downloadList
        };
	   let response = this.api.platformApi.batchDownload(params)
      this.flag2=false;
    },
    //多选框
    handleSelectionChange(val) {
      //这个val是所有已选中的json的数组
      this.multipleSelection = val.ptnsrl;
    },
   async downloadsExcelDownTransfer() {
      this.flag1=true;
      let _this = this;

      let param = {
        channel: this.$route.params.channel,
        subNo: this.$route.params.subNo,
        quyStDt: this.formInline.quyStDt.replace(/-/g, ""),
        quyEndDt: this.formInline.quyEndDt.replace(/-/g, ""),
      };

      console.log(param);
      let response = await this.api.postLoanManagementApi.downloadsExcelDownTransfer(param);
      console.log(response);
      this.flag1=false
      // window.location = response;
    },
    query(){
				this.page.startNum = 1;
				this.transaction();
			},
    async transaction() {
      this.loading = true;
      var params = {
        channel: this.$route.params.channel,
        subNo: this.$route.params.subNo,
        quyStDt: this.formInline.quyStDt.replace(/-/g, ""),
        quyEndDt: this.formInline.quyEndDt.replace(/-/g, ""),
        pageSize: this.page.pageSize,
        pageNo: this.page.startNum,
      };
      console.log("================", params);
      let response = await this.api.platformApi.transaction(params);
      // for(var index in response.data.data){
      // 	response.data.data[index] = JSON.parse(response.data.data[index])
      // }

      let showDatas = response.data.data;
      if (showDatas != null && showDatas != undefined) {
        let total = showDatas.length;
        console.log("====total=====", total, "====", this.page.pageSize);
        this.total = total;
        showDatas.map((item) => {
          item.times =
            item.dte.substring(0, 4) +
            "-" +
            item.dte.substring(4, 6) +
            "-" +
            item.dte.substring(6, 8) +
            " " +
            item.tme.substring(0, 2) +
            ":" +
            item.tme.substring(2, 4) +
            ":" +
            item.tme.substring(4, 6);
          return item;
        });

        // for(let index in showDatas){
        // 	let showData = showDatas[index];
        // 	showData.aclamt = showData.aclamt/ 100;
        // 	console.log(typeof showData.aclamt)
        // 	if(showData.tye == 'C'){
        // 		showData.aclamt = "+"+showData.aclamt
        // 	}else if(showData.tye == 'T'){
        // 		showData.aclamt = "-"+showData.aclamt;
        // 	}
        // }

        //paixu
        showDatas.sort(function (b, a) {
          return new Date(a.times).getTime() - new Date(b.times).getTime();
        });

        this.showDatas = showDatas;
        this.list = showDatas.slice(this.page.startNum - 1, this.page.pageSize);
        console.log("------------------------list", this.list);
      } else {
        this.total = 0;
      }
      this.loading = false;
      // this.total = response.data.data.total;
    },

    async printTransferVoucher(subno, ptnsrl) {
      this.loading = true;
      var params = {
        subNo: subno,
        ptnSrl: ptnsrl,
        channel: this.$route.params.channel,
      };
      console.log("================", params);
      let response = await this.api.platformApi.printTransferVoucher(params);
      console.log("=============0000", response);
      console.log("=============pdf", response.data.data.pdffile);
      window.open(response.data.data.pdffile);
      this.loading = false;
    },

    rest() {
      this.formInline.quyStDt = "";
      this.formInline.quyEndDt = "";
      this.query();
    },

    userdetails(row) {
      this.printTransferVoucher(row.subno, row.ptnsrl);
    },

    handleSizeChange(val) {
      //this.page.startNum = 1;
      this.page.pageSize = val;
      // this.list = this.showDatas.slice(
      //   (this.page.startNum - 1) * this.page.pageSize,
      //   this.page.startNum * this.page.pageSize
      // );
      this.transaction();
    },
    currentChange(pageNo) {
      this.page.startNum = pageNo;
      // this.list = this.showDatas.slice(
      //   (this.page.startNum - 1) * this.page.pageSize,
      //   this.page.startNum * this.page.pageSize
      // );
      this.transaction();
      console.log("========pageNo:", pageNo);
    },

    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
  },
};
</script>
<style scoped>
a:link {
  text-decoration: none;
}

.tabbox {
  text-align: left;
}

#table {
  background-color: rgba(29, 144, 255, 0);
}

.el-dialog {
  text-align: left;
}

>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgba(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}

.el-dialog button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

.search {
  background-color: white;
  padding: 15px 20px 100px 20px;
  overflow: hidden;
}

#grid {
  margin-right: 15px;
  padding: 0px;
  border-radius: 5px;
}

#page {
  float: right;
  margin-top: 15px;
}

.sx {
  float: right;
  margin-right: 70px;
}

.buttonbox button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
</style>