<template>
  <div id="taskList">
    <template>
      <div>
        <el-form :inline="true" :model="taskReq" class="demo-form-inline">
          <el-row :gutter="0">
            <el-col :span="6" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="作业单编号:">
                  <el-input
                    v-model="taskReq.thirdParty"
                    class="el-select"
                    placeholder="请输入查询"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <el-form-item id="anniu">
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="query"
                  >查询</el-button
                >
                <el-button
                  type="primary"
                  @click="rest"
                  icon="el-icon-refresh-right"
                  >重置</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div style="text-align: initial; margin-bottom: 10px" class="buttonbox">
        <el-button type="primary" icon="el-icon-upload2" @click="openUpload()">导入</el-button>
        <el-button type="primary" icon="el-icon-plus" @click="add()">添加</el-button>
        <uploadTask @uploadData="uploadData" ref="uploadTask"></uploadTask>
      </div>
      
      <el-table
        id="table"
        v-loading="loading"
        :data="taskList"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
      <el-table-column type="index"  label="序号" align="center" />
        <el-table-column
          prop="thirdParty"
          label="作业单编号"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="taskorName"
          label="供应方名称"
          :show-overflow-tooltip="true"
          align="center"
        />

        <el-table-column
          prop="type"
          label="类型"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <div v-for="(item, index) in taskType" v-bind:key="index" >
              <div v-if="item.datavalue == scope.row.type">
                {{  item.name }}
              </div>
            </div>
            </template>
        </el-table-column>

        <el-table-column
          prop="clientBillNo"
          label="客户单号"
          :show-overflow-tooltip="true"
          align="center"
        />

        <el-table-column
          prop="amount"
          label="金额"
          :show-overflow-tooltip="true"
          align="center"
        >
         <template slot-scope="scope">
            <span>{{accDiv(scope.row.amount , 100)}}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="state"
          label="状态"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <div v-for="(item, index) in taskState" v-bind:key="index" >
              <div v-if="item.datavalue == scope.row.state">
                {{  item.name }}
              </div>
            </div>
            </template>
        </el-table-column>

        <el-table-column
          prop="expectedEndTime"
          label="预计结束时间"
          :show-overflow-tooltip="true"
          align="center"
        />

        <el-table-column
          prop="createTime"
          label="录入时间"
          :show-overflow-tooltip="true"
          align="center"
        />
        <el-table-column
          prop="bzCreateTime"
          label="创建时间"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>

        <el-table-column label="操作" fixed="right" >
            <template slot-scope="scope">
              <el-button type="text" size="small"  @click="detail(scope.row)" style="color: #FFAC1D">详情</el-button>
              <el-button type="text" size="small"  @click="edit(scope.row)" style="color: #FFAC1D">修改</el-button>
            </template>
          </el-table-column>

      </el-table>

      <el-pagination
        id="page"
        background
        @size-change="handleSizeChange"
        @current-change="currentChange"
        :page-sizes="[10, 15, 20]"
        :page-size="taskReq.pageSize"
        :current-page="taskReq.pageNo"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>

      <el-dialog title="保存作业单" top="6vh" :visible.sync="dialogVisible" width="85%" class="roll-dialog">
            <el-form :model="dataForm" ref="dataForm" label-width="180px">
                <div id="button">
                  <el-row>
                    <el-col :span="1">
                      <el-button type="primary" align="left" icon="el-icon-plus" class="button_margin_top button_size"
                        >基本信息</el-button
                      >
                    </el-col>
                  </el-row>
                </div>
                <div class="textTips">
                  <el-divider class="el-divider"></el-divider>
                </div>
              <el-row>
                <el-form-item label="作业单编号" style="display:inline-block" required>
                    <el-col :span="22">
                      <el-input v-if="save" v-model="dataForm.thirdParty"></el-input>
                      <el-input v-else v-model="dataForm.thirdParty" disabled></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="供应方主体类型" style="display:inline-block">
                  <el-col :span="22">
                    <el-select placeholder="类型" v-model="dataForm.taskorType" clearable>
                        <el-option v-for="item in subjectType" :label="item.name" :value="item.sort" :key="item.name+item.sort"></el-option>
                      </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item label="供应方第三方用户主键" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="dataForm.taskorThirdParty"></el-input>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="供应方证件号" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="dataForm.taskorIdno"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="供应方名称" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="dataForm.name"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="状态" style="display:inline-block">
                  <el-col :span="22">
                      <el-select placeholder="状态" v-model="dataForm.state" clearable>
                        <el-option v-for="item in taskState" :label="item.name" :value="item.sort" :key="item.name+item.sort"></el-option>
                      </el-select>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="内容描述" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="dataForm.content"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="客户单号" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="dataForm.clientBillNo"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="预计结束时间" style="display:inline-block">
                    <el-col :span="22">
                      <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                        v-model="dataForm.expectedEndTime"
                        type="datetime"
                        placeholder="选择日期">
                      </el-date-picker>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="金额" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="dataForm.amount"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="作业单类型" style="display:inline-block">
                  <el-col :span="22">
                    <el-select placeholder="类型" v-model="dataForm.type" clearable>
                        <el-option v-for="item in taskType" :label="item.name" :value="item.datavalue" :key="item.name+item.sort"></el-option>
                      </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item label="状态描述" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="dataForm.stateDescription"></el-input>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="关联任务清单号" style="display:inline-block">
                    <el-col :span="22">
                      <el-select v-model="dataForm.taskTrackNos" multiple filterable allow-create default-first-option placeholder="请选择关联任务清单号">
                        <el-option v-for="item in options" :label="item" :value="item" :key="item"></el-option>
                      </el-select>
                    </el-col>
                </el-form-item>
                <el-form-item label="备注" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="dataForm.remark"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="创建时间" style="display:inline-block">
                    <el-col :span="22">
                      <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                        v-model="dataForm.createTime"
                        type="datetime"
                        placeholder="选择日期">
                      </el-date-picker>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row v-if="dataForm.type!='' && dataForm.type!=undefined">
                <detail :dataForm="dataForm.contentData" ref="detail"></detail>
              </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm()">确 定</el-button>
            </div>
        </el-dialog>

    </template>
    <!-- </div> -->
  </div>
</template>
<script>
import detail from './detail'
import uploadTask from './uploadTask'

export default {
  components:{
    detail,
    uploadTask
  },
  data() {
    return {
      taskReq: {
        thirdParty: "",
        pageNo: 1,
        pageSize: 10,
      },
      dataForm:{},
      total: 0,
      taskList:[{id:'dd9e4d0135edd6c9b0d9b1cc8ccdf810',taskorName:'上海和耀供应链管理有限公司',blockId:'',clientBillNo:'0732094258',expectedEndTime:'2022-03-11 23:38:45',type:'G545435437',amount:999,state:'1',type:'G545435437'
                ,stateDescription:'已签收',proofName:null,tenantId:'17744230bb704e05bde6a8b4fccc42da',taskor:'2de9826e821ba9a2c691a0d4b566a166'
                ,taskorThirdParty:'409281807612116992',content:null,thirdParty:'20220310001173',enabled:'1',createTime:'2022-04-28 11:45:05',taskTrackNos:[]
                ,bzCreateTime:'2022-04-28 11:45:05',provider:'c38d23b092c3f2d9fc86a160bfb3b1ba',contentData:{deliveryProvince:'广东省',deliveryCity:'广州市',deliveryArea:'天河区'
                ,receivingProvince:'广东省',receivingCity:'广州市',receivingArea:'荔湾区',routingCode:'XL21080262924',transportModes:'1',carType:'1',carCaptain:1.2
                ,carTemperature:'1',transportState:'1',deliveryContact:'小明',deliveryContactPhone:'17787319862',deliveryAddress:'荔湾区',receivingAddress:'天河区'
                ,acceptorName:null,cargoWeight:'15.541000',cargoMeasurement:'54.393500',cargoPieces:'1000',carNumber:'粤ADDS',insuranceNo:null,insuranceCorpName:'黑龙江省北安农垦宏发货物服务有限公司'
                ,driverName:'张三',driverIdno:'456789123454321',driverPhone:'17654281971',ip:'174.981.112',mac:'176.891.678',minRequiredPickUpPeriod:'2022-04-28 11:45:05'
                ,maxRequiredPickUpPeriod:'2022-04-28 11:45:05',minEstimatedArrivalPeriod:'2022-04-28 11:45:05',maxEstimatedArrivalPeriod:'2022-04-28 11:45:05',acceptorType:'1'
                ,acceptorName:'哈克收到',acceptorIdno:'21352466564534',acceptorContact:'李四',acceptorContactPhone:'16748910121',pickUpTime:'2022-04-28 11:45:05'
                ,arrivalTime:'2022-04-28 11:45:05',locateInfos:[{currentTime:'2022-04-28 11:45:05',longitude:'116.397128',latitude:'39.916527'}]}}],
      stateDiction: {
        code: "taskState",
      },
      typeDiction: {
        code: "taskType",
      },
      subjectDiction: {
        code: "subjectType",
      },
      subjectType:[],
      taskType:[],
      taskState:[],
      dialogVisible:false,
      save:false,
      dialogFormVisible:false,
      loading:false,
      //管理清单号
      options:['12312421','791274121','123141251'],
    };
  },

  created: function () {
    this.findDictionariesOrderTypeList(this.typeDiction);
    this.findDictionariesOrderStateList(this.stateDiction);
    this.findDictionariesSubjectTypeList(this.subjectDiction)
  },
  mounted: function (){
    
  },
  methods: {
    query(){
      this.loadTable()
    },
    rest(){
      this.taskReq.thirdParty=''
      this.loadTable()
    },
    loadTable(){

    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    handleSizeChange(val) {
      this.taskReq.pageSize = val;
      this.loadTable()
    },
    currentChange(pageNo) {
      this.taskReq.pageNo = pageNo;
      this.loadTable()
    },
    //修改
    edit(row){
      this.save = false 
      this.dialogVisible = true
      this.dataForm = row
      console.log(this.dataForm);
    },
    submitForm(){
      this.dialogVisible = false
      console.log(this.dataForm);
    },
    //添加
    add(){
      this.dataForm = {}
      this.dialogVisible = true
      this.save = true 
    },
    openUpload(){
      this.$refs.uploadTask.openUpload();
    },
    async findDictionariesOrderTypeList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典", response);
				this.taskType = response.data.data;
		},
    async findDictionariesOrderStateList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典", response);
				this.taskState = response.data.data;
		},
    async findDictionariesSubjectTypeList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典", response);
				this.subjectType = response.data.data;
		},
    uploadData(data){
      console.log(data);
      this.uploadTable = data
      this.loading = true;
      setTimeout(() => {
          this.loading = false;
          this.$message({
          message: '请稍后刷新数据！',
          type: 'success'
        });
        }, 2000);
    },
    detail(row){
      this.$router.push({path: '/taskDetail',query: {task: row}})
    }
  },
};
</script>
<style scoped>
/* .repaylist_page_class {
  width: 1200px;
  height: 100%;
} */
#taskList {
  background-color: white;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 100px;
}
#taskList .marginCss {
  margin-top: 20px;
  margin-left: 6px;
}
#taskList .grid-anniu button {
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
  margin: 10px;
}
#anniu {
  border: 0;
}

#anniu button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
#table {
  width: 100%;
  background-color: rgba(29, 144, 255, 0);
}
#orderList .dialogBox {
  text-align: left;
}
#page {
  float: right;
  margin-top: 15px;
}
.buttonbox button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
.roll-dialog ::v-deep .el-dialog .el-dialog__body {
    padding: 3px 30px;
    overflow-y: scroll;
    height: 600px;
 }
</style>