<template>
  <div id="menutree">
    <el-container class="header">
      <el-aside class="header2" width="100%">
        <!-- 头部 -->
        <el-header class="header3">
          <el-row :gutter="20" style="height: 100%">
            <el-col :span="3">
              <div><span class="ii">后台系统</span></div></el-col
            >
            <el-col :span="3" :offset="16" style="height: 100%">
              <div class="grid-content">
                欢迎你：{{ this.$getCookie("name") }}
              </div>
            </el-col>
            <template>
              <el-button type="text" @click="open" class="edit_buttom_class"
                >退出登录</el-button
              >
            </template>
          </el-row>
        </el-header>
        <el-container>
          <!-- 侧边栏 -->
          <el-aside width="13%" class="sidebar_class">
            <el-menu @select="handleSelect" router>
              <el-submenu
                :index="item.module"
                v-for="(item, index) in menuList"
                :key="index"
              >
                <template slot="title">
                  <i style="font-size: 20px" :class="icons[index]"></i>
                  <span class="ii">{{ item.module }}</span>
                </template>
                <el-menu-item
                  id="item"
                  @focus="handlerFoucus"
                  v-for="(citem, cindex) in item.list"
                  :key="cindex"
                  :index="citem.componentPath"
                  @click="xx(citem.name, $event)"
                >
                  {{ citem.name }}
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </el-aside>

          <el-container>
            <el-header id="body">
              <el-breadcrumb id="header4" separator="">
                <el-breadcrumb-item :to="{ path: '' }"
                  ><span style="color: #1d90ff">{{
                    columnName
                  }}</span></el-breadcrumb-item
                >
              </el-breadcrumb>
            </el-header>
            <!-- 主体 -->
            <el-main id="header5"><router-view></router-view></el-main>
          </el-container>
        </el-container>
      </el-aside>
    </el-container>
  </div>
</template>



<script>
import router from "./../router/index";

export default {
  data() {
    return {
      openeds: [],
      uniqueOpened: false,
      menuList: [],
      userName: "",
      columnName: "用户管理",
      icons: [
        "el-icon-s-platform",
        "el-icon-s-tools",
        "el-icon-star-on",
        "el-icon-s-fold",
        "el-icon-s-unfold",
      ],
    };
  },
  mounted() {
    this.loadList();
  },

  methods: {
    async loadList() {
      var me = this;
      var params = {
        sessionId: this.$getCookie("sessionId"),
      };
      me.api.platformApi
        .PermissionMenu(params)
        .then(function (response) {
          console.log(response.data.data);
          me.menuList = response.data.data;

          for (let i = 0; i < me.menuList.length; i++) {
            me.openeds.push(me.menuList[i].module);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {});
    },
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
    handleSelect(key, keyPath) {},
    getCookie: function (cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      // console.log("获取cookie,现在循环")
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        // console.log(c)
        while (c.charAt(0) === " ") c = c.substring(1);
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    open() {
      this.$confirm("确定要退出登录吗？", "退出确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.$router.push({ path: `/login/${localStorage.getItem('tenantId')}` });
        })
        .catch(() => {});
    },
    clearCookie: function (cname) {
      this.setCookie(cname, "", -1);
    },
    xx: function (columnName, target) {
      // console.log(target)
      this.columnName = columnName;
      target.$el.style = "";
      // console.log(this)
    },
    handlerFoucus(event) {
      console.log(event, "xx");
    },
  },
  router,
};
</script>

<style scoped>
#menutree .sidebar_class {
  text-align: center;
  height: calc(100vh - 60px);
  overflow-y: auto;
}
#menutree .edit_buttom_class {
  vertical-align: bottom;
  color: white;
}
#header5 {
  padding: 10px;
}
#header4 {
  line-height: 56px;
  /* height: 55px; */
}
#body {
  height: 56px !important;
  background-color: white;
}
#menutree .el-header {
  /* background-color: #b3c0d1; */
  color: rgb(252, 248, 248);
  line-height: 50px;
}
/* #menutree .el-button {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgba(228, 228, 228, 1);
} */
body {
  margin: 0;
}
#menutree .ii {
  font-weight: bold;
  font-size: 16px;
  line-height: 60px;
}
#menutree .el-aside {
  background-color: white;
  color: #333;
}
>>> .el-submenu__title {
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
  background-color: #f9f9f9;
}
>>> .el-submenu.is-active .el-submenu__title {
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}
.el-menu > .el-menu-item {
  padding-left: 85px !important;
  border-bottom: 1px solid rgb(153, 153, 153, 0.2);
}
#menutree .el-menu-item.is-active {
  background-color: #e6f7ff !important;
  border-right: 3px solid #409eff;
  padding-left: 88px !important;
}
#menutree .el-submenu__title {
  background-color: #f9f9f9;
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}
#menutree .header {
  border: 0px solid #eee;
}
#menutree .header2 {
  background-color: rgb(238, 241, 246);
  /* background-color: white; */
}
#menutree .header3 {
  background: #1d90ff;
  height: 50px;
}
#menutree .grid-content {
  height: 100%;
  line-height: 60px;
}
</style>


