<template>
  <div id="whiteList">
    <el-form
      ref="whiteListForm"
      :model="whiteListForm"
      class="demo-form-inline"
      :inline="true"
    >
      <el-row>
        <el-col :span="6" align="left">
          <el-form-item label="客户名字" label-width="68px">
            <el-input
              v-model="whiteListForm.customerName"
              placeholder="请输入查询"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7" align="left">
          <el-form-item label="统一社会信用代码">
            <el-input
              v-model="whiteListForm.idno"
              placeholder="请输入查询"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" align="left">
          <el-form-item label="风控评级">
            <el-select v-model="whiteListForm.grade">
              <el-option value="">请选择</el-option>
              <el-option
                v-for="(item, index) in gradeList"
                :key="index"
                :label="item.grade"
                :value="item.grade"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="法人名字" align="left">
            <el-input
              v-model="whiteListForm.legalName"
              placeholder="请输入查询"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10" align="left">
          <el-form-item label="加入白名单时间段">
            <el-date-picker
              v-model="whiteListForm.operationTimeStart"
              type="date"
              placeholder="选择日期"
              pattern="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker
            >-
            <el-date-picker
              v-model="whiteListForm.operationTimeEnd"
              type="date"
              placeholder="选择日期"
              pattern="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="query" class="button_size_two"
            >查询</el-button
          >
          <el-button type="primary" class="button_size_two" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-form>

    <div>
      <el-button
        type="primary"
        icon="el-icon-upload2"
        @click="download"
        class="button_size_two"
        >导出</el-button
      >
      <el-button
        type="primary"
        icon="el-icon-download"
        @click="bulkJoin"
        class="button_size_four"
        >批量加入</el-button
      >
    </div>

    <el-dialog
      title="批量加入"
      :visible.sync="bulkJoinBulletFrame"
      width="50%"
      style="height: 100%"
      :close-on-click-modal="false"
    >
    <el-form :inline="true" :model="batchQueryForm" ref="batchQueryForm">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="信用代码:" prop="idno">
            <el-input
              v-model="batchQueryForm.idno"
              placeholder="请输入信用代码"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司名称:" prop="customerName">
            <el-input
              v-model="batchQueryForm.customerName"
              placeholder="请输入公司名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="法人姓名:" prop="legalName">
            <el-input
              v-model="batchQueryForm.legalName"
              placeholder="请输入法人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="batchQueryFormSubmit">查询</el-button>
            <el-button type="primary" @click="resetBatchQueryForm">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

      <el-table
        :data="noWhitelistAddedTable"
        ref="noWhitelistAddedTable"
        id="table"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
        @selection-change="selectionLineChangeHandle"
        :row-key="getRowKeys"
      >
        <el-table-column
          type="selection"
          align="center"
          :reserve-selection="true"
        >
        </el-table-column>
        <el-table-column prop="num" label="序号" align="center">
          <template slot-scope="scope">
            <span>{{
              scope.$index +
              1 +
              (noWhitelistAddedPage.pageNo - 1) * noWhitelistAddedPage.pageSize
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="customerName"
          label="客户名字"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="idno"
          label="统一社会信用代码"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="legalName"
          label="法人名称"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        id="bulletFramePage"
        background
        @size-change="noWhitelistAddedHandleSizeChange"
        @current-change="noWhitelistAddedCurrentChange"
        :page-sizes="[10, 15, 20]"
        :page-size="noWhitelistAddedPage.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="noWhitelistAddedPage.total"
        :current-page="noWhitelistAddedPage.pageNo"
      >
      </el-pagination>
      <div id="anniu" slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="add">添加</el-button>
      </div>
    </el-dialog>

    <el-table
      :data="whiteListTable"
      id="table"
      :header-cell-style="tableHeader"
      :cell-style="tableCell"
      border
      v-loading="loading"
    >
      <el-table-column prop="num" label="序号" align="center">
        <template slot-scope="scope">
          <span>{{
            scope.$index + 1 + (page.pageNo - 1) * page.pageSize
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="customerName"
        label="客户名字"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="idno"
        label="统一社会信用代码"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="legalName"
        label="法人名称"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="platformName"
        label="所属平台"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="corpAuthTime"
        label="企业认证时间"
        :show-overflow-tooltip="true"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.corpAuthTime == null">未认证</span>
          <span v-else>{{ scope.row.corpAuthTime }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="personAuthTime"
        label="法人认证时间"
        :show-overflow-tooltip="true"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.personAuthTime == null">未认证</span>
          <span v-else>{{ scope.row.personAuthTime }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="operationTime"
        label="加入白名单时间"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="maxCreditAmount"
        label="最大授信金额"
        :show-overflow-tooltip="true"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.maxCreditAmount / 100 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="serviceChargeRate"
        label="手续费率"
        :show-overflow-tooltip="true"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.serviceChargeRate / 10000 }}%
        </template>
      </el-table-column>
      <el-table-column
        prop="creditInterestRate"
        label="利率"
        :show-overflow-tooltip="true"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.creditInterestRate / 10000 }}%
        </template>
      </el-table-column>
      <el-table-column
        prop="penaltyInterestRate"
        label="罚息比例"
        :show-overflow-tooltip="true"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.penaltyInterestRate / 10000 }}%
        </template>
      </el-table-column>
      <el-table-column
        prop="grade"
        label="风控评级"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        label="操作"
        :show-overflow-tooltip="true"
        align="center"
        width="200"
      >
        <template slot-scope="scope">
          <el-link
            type="primary"
            icon="el-icon-fetchByIdSysUserList"
            :underline="false"
            size="medium"
            @click="details(scope.row)"
            >详情</el-link
          >
          <el-link
            type="success"
            icon="el-icon-fetchByIdSysUserList"
            :underline="false"
            size="medium"
            @click="setUp(scope.row)"
            >设置</el-link
          >
          <el-link
            type="warning"
            icon="el-icon-fetchByIdSysUserList"
            :underline="false"
            size="medium"
            @click="removeFromWhiteList(scope.row)"
            >移出白名单</el-link
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 设置弹框 -->
    <el-dialog
      title="设置"
      :visible.sync="setTheBulletFrame"
      width="50%"
      style="height: 100%"
    >
      <el-form
        id="setUpCreditForm"
        ref="setUpCreditForm"
        :model="setUpCreditForm"
        label-position="right"
        label-width="auto"
      >
        <el-form-item label="利率(%)">
          <el-input v-model="setUpCreditForm.creditInterestRate"></el-input>
        </el-form-item>
        <el-form-item label="手续费率(%)">
          <el-input v-model="setUpCreditForm.serviceChargeRate"></el-input>
        </el-form-item>
        <el-form-item label="最大授信额度">
          <el-input v-model="setUpCreditForm.maxCreditAmount"></el-input>
        </el-form-item>
        <el-form-item label="罚息利率(%)">
          <el-input v-model="setUpCreditForm.penaltyInterestRate"></el-input>
        </el-form-item>
        <el-form-item label="风控评级">
          <el-select
            v-model="setUpCreditForm.grade"
            @change="setUpCreditFormGradeChange($event)"
          >
            <el-option
              v-for="(item, index) in gradeList"
              :key="index"
              :label="item.grade"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div id="anniu" slot="footer" class="dialog-footer">
        <el-button @click="setUpCancel">取消</el-button>
        <el-button type="primary" @click="updateCreditSetUp">设置</el-button>
      </div>
    </el-dialog>

    <el-pagination
      id="page"
      background
      @size-change="handleSizeChange"
      @current-change="currentChange"
      :page-sizes="[10, 15, 20]"
      :page-size="page.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="page.total"
      :current-page="page.pageNo"
    >
    </el-pagination>
  </div>
</template>
<script>
import {accMul} from '../../../util/calculateUtils';
export default {
  data() {
    return {
      batchQueryForm: {
        idno: '',
        customerName: '',
        legalName: ''
      },
      loading: false,
      setUpCreditForm: {
        creditInterestRate: 0,
        serviceChargeRate: 0,
        maxCreditAmount: 0,
        penaltyInterestRate: 0,
        grade: "",
        riskId: "",
      },
      gradeList: [],
      gradeSettingList: [],
      selectWhiteList: [],
      setTheBulletFrame: false,
      bulkJoinBulletFrame: false,
      whiteListForm: {
        customerName: "",
        idno: "",
        grade: "",
        legalName: "",
        operationTimeStart: "",
        operationTimeEnd: "",
      },
      whiteListTable: [],
      noWhitelistAddedTable: [],
      noWhitelistAddedPage: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      param: {
        product: "",
        creditSettingId:"",
      },
      risk: "",
    };
  },
  mounted: function () {
    this.param = JSON.parse(localStorage.getItem("whiteList"));
    console.log("接收参数", this.param);
    this.findWhiteListSets();
    this.gradeSettings();
  },
  methods: {
    // batchQueryFormSubmit
    batchQueryFormSubmit(){
      this.noWhitelistAddedPage.pageNo = 1;
      this.selectWhiteList = [];
      this.$refs.noWhitelistAddedTable.clearSelection();
      this.notAdded();
    },
    //
    resetBatchQueryForm(){
      this.$refs['batchQueryForm'].resetFields();
      this.batchQueryFormSubmit();
    },
    //设置弹框 发生改变时触发
    setUpCreditFormGradeChange(e) {
      console.log(this.gradeSettingList[e])
      this.setUpCreditForm.creditInterestRate =
          this.gradeSettingList[e].creditInterestRate / 10000;
        this.setUpCreditForm.serviceChargeRate =
          this.gradeSettingList[e].serviceChargeRate / 10000;
        this.setUpCreditForm.maxCreditAmount =
          this.gradeSettingList[e].maxCreditAmount / 100;
        this.setUpCreditForm.penaltyInterestRate =
          this.gradeSettingList[e].penaltyInterestRate / 10000;
    },
    //重置
    reset() {
      this.whiteListForm.customerName = "";
      this.whiteListForm.idno = "";
      this.whiteListForm.grade = "";
      this.whiteListForm.legalName = "";
      this.whiteListForm.operationTimeStart = "";
      this.whiteListForm.operationTimeEnd = "";
    },
    getCookie: function (cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      // console.log("获取cookie,现在循环")
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        // console.log(c)
        while (c.charAt(0) === " ") c = c.substring(1);
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    //导出
    download() {
      this.exportWhiteList();
    },
    async exportWhiteList() {
      var params = {
        product: this.param.product,
        pageNo: this.page.pageNo,
        pageSize: -1,
        customerName: this.whiteListForm.customerName,
        idno: this.whiteListForm.idno,
        grade: this.whiteListForm.grade,
        legalName: this.whiteListForm.legalName,
        operationTimeStart: this.whiteListForm.operationTimeStart,
        operationTimeEnd: this.whiteListForm.operationTimeEnd,
        creditSettingId:this.param.creditSettingId
      };
      console.log("params======", params);
      let response = await this.api.preLoanManagementApi.exportWhiteList(
        params
      );
      console.log("导出返回结果", response);
      // window.location = response;
    },
    //移出白名单
    removeFromWhiteList(row) {
      this.updateWhiteListInvalid(row.whiteListId);
    },
    //修改为无效白名单
    async updateWhiteListInvalid(id) {
      var params = {
        id: id,
      };
      let response = await this.api.preLoanManagementApi.updateWhiteListInvalid(
        params
      );
      console.log("=========response", response.data.data);
      if (response.data.data == true) {
        this.$message.success("移出白名单成功");
        this.findWhiteListSets();
      }
      if (response.data.data == false) {
        this.$message.error("移出白名单失败");
      }
    },
    //根据risk查询授信信息
    async findCreditByRisk(risk) {
      var params = {
        riskId: risk,
        creditSettingId:this.param.creditSettingId
      };
      let response = await this.api.preLoanManagementApi.findCreditByRisk(
        params
      );
      // console.log("=========response", response.data.data);
      if (null != response.data.data) {
        this.setUpCreditForm = response.data.data;
        this.setUpCreditForm.creditInterestRate =
          this.setUpCreditForm.creditInterestRate / 10000;
        this.setUpCreditForm.serviceChargeRate =
          this.setUpCreditForm.serviceChargeRate / 10000;
        this.setUpCreditForm.maxCreditAmount =
          this.setUpCreditForm.maxCreditAmount / 100;
        this.setUpCreditForm.penaltyInterestRate =
          this.setUpCreditForm.penaltyInterestRate / 10000;
      }
      console.log("=========根据risk查询授信信息", this.setUpCreditForm);
    },
    setUp(row) {
      this.setTheBulletFrame = true;
      this.risk = row.risk;
      console.log("risk===---", this.risk);
      this.findCreditByRisk(row.risk);
    },
    getRowKeys(row) {
      // console.log("risk=========", row.id);
      return row.id;
    },
    //查询产品等级
    async gradeSettings() {
      var params = {
        id: this.param.creditSettingId,
      };
      let response = await this.api.preLoanManagementApi.gradeSettingList(
        params
      );
      console.log("=========response", response.data.data);
      if (null != response.data.data) {
        this.gradeSettingList = response.data.data;
        for (var i = 0; i < this.gradeSettingList.length; i++) {
          this.gradeList.push({
            id: i,
            grade: this.gradeSettingList[i].grade,
          });
          // this.gradeList.push(this.gradeSettingList[i].grade);
        }
      }
      console.log("=========产品信息", this.gradeSettingList);
    },
    //选中白名单
    selectionLineChangeHandle(val) {
      console.log("val====", val);
      this.selectWhiteList = val;
      console.log("选中白名单====", this.selectWhiteList);
    },
    //查询
    query() {
      this.page.pageNo = 1;
      this.findWhiteListSets();
    },
    add() {
      console.log("选中白名单====", this.selectWhiteList);
      var arr = [];
      for (var i = 0; i < this.selectWhiteList.length; i++) {
        arr.push(this.selectWhiteList[i].id);
      }
      if (arr.length > 0) {
        this.createWhiteListsAccordingToRisk(arr);
      }else{
        this.$message.error("请选着要添加的企业");
      }
    },
    setUpCancel() {
      this.setTheBulletFrame = false;
    },
    updateCreditSetUp() {
      //将初始化进来等级改为下标
      if(isNaN(this.setUpCreditForm.grade)){
        for(let index in this.gradeSettingList){
          if(this.setUpCreditForm.grade == this.gradeSettingList[index].grade){
            this.setUpCreditForm.grade = index;
          }
        }
        if(isNaN(this.setUpCreditForm.grade)){
          this.$message.error("未知等级");
          return;
        }
      }
      console.log(JSON.stringify(this.setUpCreditForm.grade))
      this.updateCredit ();
    },
    //更新授信
    async updateCredit() {
      var params = {
        riskId: this.risk,
        operator: this.getCookie("sessionId"),
        grade: this.gradeSettingList[this.setUpCreditForm.grade].grade,
        creditInterestRate: accMul(this.setUpCreditForm.creditInterestRate, 10000),
        maxCreditAmount: accMul(this.setUpCreditForm.maxCreditAmount, 100),
        penaltyInterestRate: accMul(this.setUpCreditForm.penaltyInterestRate, 10000),
        serviceChargeRate: accMul(this.setUpCreditForm.serviceChargeRate, 10000),
        creditSettingId:this.setUpCreditForm.creditSettingId,
      };

      console.log("-----------修改授信参数", params);
      let response = await this.api.preLoanManagementApi.updateCredit(params);
      console.log("-----------修改授信返回参数", response);
      if (response.data.code == 200) {
        this.$message.success("修改成功");
      } else {
        this.$message.error("修改失败");
      }
      this.setTheBulletFrame = false;
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.findWhiteListSets();
      }, 3000);
    },
    cancel() {
      this.bulkJoinBulletFrame = false;
    },
    bulkJoin() {
      Object.assign(this.$data.batchQueryForm,this.$options.data().batchQueryForm);
      this.bulkJoinBulletFrame = true;
      this.noWhitelistAddedPage.pageNo = 1;
      this.noWhitelistAddedPage.pageSize = 10;
      console.log("aaa", this.noWhitelistAddedPage);
      this.$nextTick(() => {
        this.selectWhiteList.forEach((row) => {
          console.log("进来了");
          this.$refs.noWhitelistAddedTable.toggleRowSelection(row, false);
        });
      });
      this.notAdded();
    },
    //根据risk批量导入白名单
    async createWhiteListsAccordingToRisk(arr) {
      var param = {
        product: this.param.product,
        risks: arr,
      };
      let response = await this.api.preLoanManagementApi.createWhiteListsAccordingToRisk(
        param
      );
      console.log("根据risk批量导入白名单返回结果", response.data.data);
      if (response.data.data == true) {
        this.bulkJoinBulletFrame = false;
        this.findWhiteListSets();
      }
    },
    //已添加白名单
    async findWhiteListSets() {
      var param = {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        customerName: this.whiteListForm.customerName,
        idno: this.whiteListForm.idno,
        grade: this.whiteListForm.grade,
        legalName: this.whiteListForm.legalName,
        operationTimeStart: this.whiteListForm.operationTimeStart,
        operationTimeEnd: this.whiteListForm.operationTimeEnd,
        product: this.param.product,
        creditSettingId:this.param.creditSettingId,
      };
      let response = await this.api.preLoanManagementApi.findWhiteListSet(
        param
      );
      console.log("已添加白名单返回结果", response.data.data);
      if (response.data.data != null) {
        this.whiteListTable = response.data.data.records;
        this.page.total = response.data.data.total;
      }
      console.log("已添加白名单返回结果集合", this.whiteListTable);
    },
    //未添加白名单
    async notAdded() {
      var param = {
        pageNo: this.noWhitelistAddedPage.pageNo,
        pageSize: this.noWhitelistAddedPage.pageSize,
        product: this.param.product,
      };
      let response = await this.api.preLoanManagementApi.findNotOnTheWhiteList(
        { ...param, ...this.batchQueryForm }
      );
      console.log("未添加白名单返回结果", response.data.data);
      if (response.data.data != null) {
        this.noWhitelistAddedTable = response.data.data.records;
        this.noWhitelistAddedPage.total = response.data.data.total;
      }
      console.log("未添加白名单返回结果集合", this.noWhitelistAddedTable);
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.findWhiteListSets();
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo;
      this.findWhiteListSets();
    },
    noWhitelistAddedHandleSizeChange(val) {
      this.noWhitelistAddedPage.pageSize = val;
      this.notAdded();
    },
    noWhitelistAddedCurrentChange(pageNo) {
      this.noWhitelistAddedPage.pageNo = pageNo;
      this.notAdded();
    },
    details(row) {
      row.creditSettingId = this.param.creditSettingId;
      localStorage.setItem("row", JSON.stringify(row));
      this.$router.push({ name: "whiteListDetails" });
    },
  },
};
</script>

<style scoped>
#whiteList {
  background-color: white;
  padding: 15px 20px 100px;
  text-align: left;
}
#page {
  float: right;
  margin-top: 15px;
}
#bulletFramePage {
  text-align: right;
  margin-top: 15px;
}
>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  /* padding-left: 0;
  padding-right: 0; */
}
#setUpCreditForm {
  margin: 0 20%;
}
.button_size_four {
  padding: 0px;
  width: 120px;
  height: 32px;
  border-radius: 5px;
}
.button_size_two {
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
#table {
  margin-top: 10px;
}
</style>