<template>
  <div id="uploadOrder">
<!-- 内层 -->
      <el-dialog :visible.sync="dialogFormVisible">
        <el-dialog
          width="70%"
          title="上传订单" v-if="this.type=='G545435437'"
          :visible.sync="innerVisible" append-to-body>
          <el-table :data="parseTable.tableData.slice((parseTable.pageNo - 1) * parseTable.pageSize,parseTable.pageSize * parseTable.pageNo)" :header-cell-style="tableHeader" :cell-style="tableCell" height="440" :key="'tableData-'+tableDataKey" border style="width: 100%">
            <el-table-column prop="index" label="序号" width="50px">
              <template slot-scope="scope">
                <span>{{(scope.$index+1) + ((parseTable.pageNo-1) * parseTable.pageSize)}}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="thirdParty" label="订单编号"/> -->
            <el-table-column prop="bargainTime" label="下单日期" :formatter="dateFormat"/>
            <el-table-column prop="clientBillNo" label="客户单号"/>
            <el-table-column prop="buyerType" label="货主类型"/>
            <el-table-column prop="buyerIdno" label="货主证件号"/>
            <el-table-column prop="orderorName" label="货主名称"/>
            <el-table-column prop="estimatedPickUpTime" label="预计提货时间" :formatter="dateTimeFormat"/>
            <el-table-column prop="estimatedArrivalTime" label="预计到达时间" :formatter="dateTimeFormat"/>
            <el-table-column prop="deliveryContact" label="发货方"/>
            <el-table-column prop="deliveryContactPhone" label="发货联系电话"/>
            <el-table-column prop="deliveryProvince" label="发货省"/>
            <el-table-column prop="deliveryCity" label="发货市"/>
            <el-table-column prop="deliveryArea" label="发货区"/>
            <el-table-column prop="deliveryDetailAddress" label="发货详细地址"/>
            <el-table-column prop="cargoName" label="货物名称"/>
            <el-table-column prop="cargoPieces" label="货物件数"/>
            <el-table-column prop="cargoWeight" label="货物重量"/>
            <el-table-column prop="cargoMeasurement" label="货物体积"/>
            <el-table-column prop="driverName" label="司机"/>
            <el-table-column prop="carNumber" label="车牌号"/>
            <el-table-column prop="driverPhone" label="司机手机号"/>
            <el-table-column prop="payeeName" label="收款人"/>
            <el-table-column prop="payeeIdno" label="收款人身份证号码"/>
            <el-table-column prop="payeePhone" label="收款人手机号"/>
            <el-table-column prop="payeeBankNo" label="开户行"/>
            <el-table-column prop="payeeBankcard" label="银行卡号"/>
            <el-table-column prop="amount" label="司机裸运费"/>
            <el-table-column prop="remark" label="备注"/>
          </el-table>
             <el-pagination id="page" background  @current-change="currentChange"
                :page-size="parseTable.pageSize"
                layout="total, prev, pager, next, jumper"
                :total="parseTable.total"
              >
              </el-pagination>
          <div slot="footer" class="dialog-footer">
            <el-button type="info" @click="innerVisible=false">关闭预览</el-button>
            <el-button type="primary" @click="onUpload()">确认上传</el-button>
          </div>
        </el-dialog>

        <el-form :model="uploadForm" :label-position="'left'" :rules="rules" ref="uploadForm">
          <el-form-item label="请选择类型">
            <el-select placeholder="类型" v-model="type" clearable>
                <el-option v-for="item in orderType"  :label="item.name" :value="item.datavalue" :key="item.sort"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上传订单：" prop="uploadJson.fileName">
            <el-upload
              class="upload-demo"
              ref="upload"
              action=""
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-success="handleSuccess"
              :on-error="handleError"
              :on-change="handleChange"
              :file-list="uploadForm.fileList"
              :limit="uploadConfig.limit"
              :accept="uploadConfig.accept"
              :data="uploadForm.uploadJson"
              :auto-upload="false">
              <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
              <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件。</div>
            </el-upload>
          </el-form-item>
        </el-form>
      </el-dialog>
  </div>
</template>
<script>
import XLSX from "xlsx"
import objectUtils from '@/util/objectUtils'
export default{
  data(){
    return{
      uploadConfig:{
        limit:1,            //最大允许上传个数
        accept:'.xls,.xlsx',//接受上传的文件类型
      },
      dialogFormVisible:false,
      //预览弹框
      innerVisible:false,
      //预览数据表key，用于重新渲染数据
      tableDataKey:false,
      //上传参数
      uploadForm:{
        fileList:[],
        sessionId:this.$getCookie("sessionId"),
        uploadUrl:process.env.VUE_APP_UPLOAD_URL,
        uploadJson:{
           type: process.env.VUE_APP_UPLOAD_TYPE,
           fileName:"",
           json:"",
           queue:process.env.VUE_APP_UPLOAD_PAYABLE_SALARY_QUENCE_NAME,
        }
      },
      type:"",
      orderType:[],
      TypeDiction: {
        code: "orderType",
      },
      rules: {
         ["uploadJson.fileName"]: [{ required: true, message: '请上传订单', trigger: 'change' }]
      },
      parseTable:{
        tableData:[],
        pageNo:1,
        pageSize:10,
        total:0
      },
      //列名
      columnNames:{
        bargainTime:0,
        clientBillNo:1,
        buyerType:2,
        buyerIdno:3,
        orderorName:4,
        estimatedPickUpTime:5,
        estimatedArrivalTime:6,
        deliveryContact:7,
        deliveryContactPhone:8,
        deliveryProvince:9,
        deliveryCity:10,
        deliveryArea:11,
        deliveryDetailAddress:12,
        acceptorContact:13,
        acceptorContactPhone:14,
        receivingProvince:15,
        receivingCity:16,
        receivingArea:17,
        receivingDetailAddress:18,
        cargoName:19,
        cargoPieces:20,
        cargoWeight:21,
        cargoMeasurement:22,
        driverName:23,
        carNumber:24,
        driverPhone:25,
        payeeName:26,
        payeeIdno:27,
        payeePhone:28,
        payeeBankNo:29,
        payeeBankcard:30,
        amount:31,
        remark:32
      }
    }
  },
  created: function () {

  },
  mounted:function (){
    this.findDictionariesOrderTypeList(this.TypeDiction);
  },
  methods: {
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    handlePreview(file){
      console.log('handlePreview,',file)
    },
    handleRemove(file, fileList){
      console.log('handleRemove',file,fileList)
    },
    handleSuccess(res, file){
      console.log('handleSuccess',res,file)
      this.$message.success('上传成功');
    },
    handleError(err,file,fileList){
      this.resetUpload();
      this.$message.error('上传失败,原因：'+err.message);
    },
    handleChange(file, fileList){
      console.log('handlechange',file,fileList);
      //重置
      if(this.type==''){
        this.$message.warning("请选择类型！")
        this.$refs.upload.clearFiles();
        return
      }
      this.parseTable={tableData:[],pageNo:1,pageSize:10,total:0};
      //加载成功，则处理
      if(file.status == 'ready'){
        this.uploadForm.uploadJson.fileName = file.name;
        const fileReader = new FileReader();
        //自定义加载文件方法
        if(this.type == 'G545435437'){
          fileReader.onload = this.logistics
        }
        fileReader.readAsBinaryString(file.raw);
      }
    },
    //重置上传数据
    resetUpload(){
      this.parseTable={tableData:[],pageNo:1,pageSize:10,total:0};
      this.uploadForm.uploadJson.fileName = "";
      this.$refs.upload.clearFiles();
    },
    logistics(e){
      const data = e.target.result;
      const workbook = XLSX.read(data, {
        type: "binary",
        cellDates: true
      });
      const wsname = workbook.SheetNames[0]; //取第一张表
      const excelTableData = workbook.Sheets[wsname];
      console.log(excelTableData)
      let exl = XLSX.utils.sheet_to_json(excelTableData,{header:1}); // 生成json表格内容,{header:1},表示第一行不为标题

      //列名
      let titles = exl[1];

      let index = 0;
      let columnNames = this.columnNames;
      let tableData = this.parseTable.tableData;
      for(let j = 1;j<exl.length;j++){
        // let thirdParty = exl[j][columnNames.thirdParty]
        // if(objectUtils.isEmpty(thirdParty)){
        //   return;
        // }

        //创建新对象
        tableData[index] = {}
        //计薪时间天数+1，原因:excel转换后，丢失几秒，XLSX插件固有bug
        // calcSalaryTime.setDate(calcSalaryTime.getDate()+1);
        //tableData[index].thirdParty = thirdParty
        tableData[index].bargainTime = this.dateFormat(null,null,exl[j][columnNames.bargainTime])
        tableData[index].clientBillNo = exl[j][columnNames.clientBillNo]
        tableData[index].buyerType = exl[j][columnNames.buyerType]
        tableData[index].buyerIdno = exl[j][columnNames.buyerIdno]
        tableData[index].orderorName = exl[j][columnNames.orderorName]
        tableData[index].estimatedPickUpTime = this.dateTimeFormat(null,null,exl[j][columnNames.estimatedPickUpTime])
        tableData[index].estimatedArrivalTime = this.dateTimeFormat(null,null,exl[j][columnNames.estimatedArrivalTime])
        tableData[index].deliveryContact = exl[j][columnNames.deliveryContact]
        tableData[index].deliveryContactPhone = exl[j][columnNames.deliveryContactPhone]
        tableData[index].deliveryProvince = exl[j][columnNames.deliveryProvince]
        tableData[index].deliveryCity = exl[j][columnNames.deliveryCity]
        tableData[index].deliveryArea = exl[j][columnNames.deliveryArea]
        tableData[index].deliveryDetailAddress = exl[j][columnNames.deliveryDetailAddress]
        tableData[index].acceptorContact = exl[j][columnNames.acceptorContact]
        tableData[index].acceptorContactPhone = exl[j][columnNames.acceptorContactPhone]
        tableData[index].receivingProvince = exl[j][columnNames.receivingProvince]
        tableData[index].receivingCity = exl[j][columnNames.receivingCity]
        tableData[index].receivingArea = exl[j][columnNames.receivingArea]
        tableData[index].receivingDetailAddress = exl[j][columnNames.receivingDetailAddress]
        tableData[index].cargoName = exl[j][columnNames.cargoName]
        tableData[index].cargoPieces = exl[j][columnNames.cargoPieces]
        tableData[index].cargoWeight = exl[j][columnNames.cargoWeight]
        tableData[index].cargoMeasurement = exl[j][columnNames.cargoMeasurement]
        tableData[index].driverName = exl[j][columnNames.driverName]
        tableData[index].carNumber = exl[j][columnNames.carNumber]
        tableData[index].driverPhone = exl[j][columnNames.driverPhone]
        tableData[index].payeeName = exl[j][columnNames.payeeName]
        tableData[index].payeeIdno = exl[j][columnNames.payeeIdno]
        tableData[index].payeePhone = exl[j][columnNames.payeePhone]
        tableData[index].payeeBankNo = exl[j][columnNames.payeeBankNo]
        tableData[index].payeeBankcard = exl[j][columnNames.payeeBankcard]
        tableData[index].amount = exl[j][columnNames.amount]
        tableData[index].remark = exl[j][columnNames.remark]
        index += 1;
      }
      console.log(this.parseTable.tableData);
      //打开预览
      this.parseTable.total = tableData.length;
      this.innerVisible = true;
      this.tableDataKey = !this.tableDataKey
      this.$refs.upload.clearFiles();
      this.dialogFormVisible = false
    },
    //格式化时间
    dateFormat(row, column, cellValue) {
      return cellValue ? this.$moment(cellValue).format("YYYY-MM-DD") : '';
    },
    dateTimeFormat(row, column, cellValue) {
      return cellValue ? this.$moment(cellValue).format("YYYY-MM-DD HH:MM") : '';
    },
    openUpload(){
      this.dialogFormVisible = true
    },
    currentChange(pageNo){
      this.parseTable.pageNo = pageNo;
    },
    async findDictionariesOrderTypeList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典", response);
				this.orderType = response.data.data;
		},
    //确认上传
    onUpload(){
      this.innerVisible=false;
      //上传TODO
      this.$emit("uploadData", this.parseTable.tableData);
    }
  }
}
</script>

<style scoped>
#uploadOrder{
padding-left: 20px;
}
#page {
float: right;
margin-top: 15px;
}
.dialog-footer{
text-align: right;
margin-top: 20px;
}
</style>