<template>
  <el-form label-width="150px" ref="formData" :model="formData" :rules="rules">
      <div>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="付款账号名称:" prop="payerName">
                <el-input v-model="formData.payerName"></el-input>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class"></div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="付款卡号:" prop="payerAcctNo">
                <el-input  v-model="formData.payerAcctNo"></el-input>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class"></div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="收款账号名称:" prop="payeeName">
                <el-input v-model="formData.payeeName"></el-input>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class"></div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="收款卡号:" prop="payeeAcctNo">
                <el-input  v-model="formData.payeeAcctNo"></el-input>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class"></div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="已支付金额:" prop="amountValue">
                <el-input type="number" @wheel.native.prevent="stopScroll($event)" v-model="formData.amountValue"></el-input>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class">（币种：CNY；单位：元）</div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="支付流水号:" prop="paymentNo">
                <el-input v-model="formData.paymentNo"></el-input>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class"></div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="付款到账日期:" prop="paymentTime">
                   <el-date-picker
                    v-model="formData.paymentTime"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="选择日期时间">
                  </el-date-picker>
                <!-- <el-input v-model="formData.name"></el-input> -->
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class"></div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="手机号码:" prop="mobile">
                <span>{{ formData.mobile }}</span>
                <!-- <el-input v-model="formData.name"></el-input> -->
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class"></div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :span="7"
            ><div>
              <el-form-item label="验证码:" prop="verificationCode">
                <el-input v-model="formData.verificationCode"></el-input>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"
            ><div>
              <div class="main_buttom_class">
                <div>
                  <el-button type="primary" @click="sendSms">{{
                    timeStr
                  }}</el-button>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="5"></el-col>
        </el-row>
      </div>
    </el-form>
</template>

<script>
export default {
  name: 'onLine',
  props:{
    mobile: {
      type: String,
      require: true,
    },
    maxPayAmount:{
      type: Number,
      default: 0,
    }
  },
  watch:{
    maxPayAmount(newValue,oldValue){
      this.formData.amountValue =  this.maxPayAmount/100
    }
  },
  data(){
    return {
      timeStr: "获取短信验证码",
      formData: {
        productId: '',
        payServicer: '',
        creditProofId: '',
        payerName: '',
        payerAcctNo: '',
        payeeName: '',
        payeeAcctNo: '',
        paymentTime: '',
        amount: 0,
        amountValue: this.maxPayAmount/100,
        paymentNo: '',
        mobile: this.mobile,
        verificationCode: '',
      },
      rules: {
        payerName: [
          { required: true, message: '付款账号名称必须', trigger: 'blur' }
        ],
        payerAcctNo: [
          { required: true, message: '付款卡号必须', trigger: 'blur' }
        ],
        payeeName: [
          { required: true, message: '收款账号名称必须', trigger: 'blur' }
        ],
        payeeAcctNo: [
          { required: true, message: '收款卡号必须', trigger: 'blur' }
        ],
        paymentTime: [
          { required: true, message: '支付时间必须', trigger: 'blur' }
        ],
        amountValue: [
          { required: true, message: '支付金额必须', trigger: 'blur' },
          { validator: (rule, value, callback) => {
            if (Number(value) <= 0) {
              return callback(new Error('支付金额不能小于0'));
            }
            let num = (Number(value) * 100);
            if(num > this.maxPayAmount){
              return callback(new Error('支付金额金额不能大于可支付金额'));
            }
            return callback();
          }, trigger: 'blur'},
        ],
        paymentNo: [
          { required: true, message: '支付单号必须', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '手机号必须', trigger: 'blur' }
        ],
        verificationCode: [
          { required: true, message: '验证码必须', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitForm(func) {
      return this.$refs['formData'].validate(func);
    },
    async loadChannel() {
      let response = await this.api.platformApi.findDictionariesEnableItemList({
        code: this.channelCode,
      });
      return response.data.data;
    },
    //发送验证码
    async sendSms() {
      if (this.timeStr !== "获取短信验证码" && this.timeStr !== "重新获取") {
        return;
      }
      if (this.formData.mobile == null || this.formData.mobile == "") {
        this.$message({
          message: "没有手机号，无法获取验证码",
          type: "warning",
        });
        return;
      }
      var params = {
        mobile: this.formData.mobile,
        smsType: 1,
      };
      console.log("sendsms====", params);
      let response = await this.api.platformApi.sendSms(params);
      console.log("验证码结果:", response);
      if (response.data.code != 200) {
        this.$message.error("验证码发送失败");
        return;
      }
      this.$message.success("验证码已发送");
      this.send_code();
    },
    send_code() {
      this.show = true;
      var i = 60;
      var timer = setInterval(() => {
        i--;
        this.timeStr = i + "秒";
        if (i <= 0) {
          clearInterval(timer);
          this.timeStr = "重新获取";
        }
      }, 1000);
    },
    stopScroll(evt) {
        evt = evt || window.event;
        if (evt.preventDefault) {
          // Firefox
          evt.preventDefault();
          evt.stopPropagation();
        } else {
          // IE
          evt.cancelBubble = true;
          evt.returnValue = false;
        }
        return false;
      },
  }
}
</script>

<style scoped>

.main_buttom_class {
  padding-left: 15px;
}


/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type='number'] {
  -moz-appearance: textfield !important;
}
</style>