<template>
  <div id="quotaRefresh">
    <el-card>
      <span>苏宁运力贷</span>
      <el-input
          placeholder="从列表中选择公司"
          v-model="corpProfile.name"
          :disabled="true"
          style="width: 300px; margin: 0 20px">
      </el-input>
      <el-button type="primary" icon="el-icon-refresh" @click="verify">刷新</el-button>
    </el-card>

    <el-card style="margin-top: 10px">
      <el-form :inline="true" :model="queryParams" ref="queryForm">
        <el-form-item label="信用代码">
          <el-input v-model="queryParams.idno" placeholder="信用代码" clearable></el-input>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input v-model="queryParams.name" placeholder="公司名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery">查询</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>

      <el-table
          ref="singleTable"
          :data="corpData"
          highlight-current-row
          @current-change="handleCurrentChange">
        <el-table-column property="idno" label="信用代码"></el-table-column>
        <el-table-column property="name" label="公司名称"></el-table-column>
        <el-table-column align="right">
          <template slot="header" slot-scope="scope">
            <el-button @click="setCurrent()">取消选择</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          small
          layout="prev, pager, next"
          :total="total"
          :page-size="queryParams.pageSize"
          :current-page="queryParams.pageNo"
          @current-change="pageChange"
          style="margin-top: 20px">
      </el-pagination>
    </el-card>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%">
      <span>您选择的公司为：{{this.corpProfile.name}}，是否刷新其额度？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleRefresh">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    return {
      corpData: [],
      queryParams: {
        pageNo: 1,
        pageSize: 5,
        idno: null,
        name: null
      },
      total: 0,
      dialogVisible: false,
      currentRow: null,
      corpProfile: {
        name: null,
        profileId: null
      }
    }
  },
  mounted() {
    this.loadList();
  },

  methods: {
    async loadList() {
      let response = await this.api.platformApi.listCorpProfile(this.queryParams);
      this.corpData = response.data.data.records;
      this.total = response.data.data.total;
    },

    pageChange(val) {
      this.queryParams.pageNo = val;
      this.loadList();
    },
    handleCurrentChange(val) {
      this.currentRow = val;
      this.corpProfile.name = val.name;
      this.corpProfile.profileId = val.id;
    },
    handleQuery() {
      this.queryParams.pageNo = 1;
      this.queryParams.pageSize = 5;
      this.loadList();
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
      this.corpProfile.name = null;
      this.corpProfile.profileId = null;
    },
    verify() {
      if (this.corpProfile.profileId == null) {
        this.$message.error("请先选择公司");
      } else {
        this.dialogVisible = true;
      }
    },
    resetForm() {
      this.queryParams.idno = null;
      this.queryParams.name = null;
    },
    async handleRefresh() {
      let response = await this.api.platformApi.orderNoticeMessage(this.corpProfile);
      const result = response.data.data;
      if (result.code === 200 && result.success) {
        this.$message.success("调用成功");
      } else {
        this.$message.error(result.message);
      }
      this.dialogVisible = false;
    }
  }
};
</script>
<style scoped>

</style>
