<template>
  <div>
          <el-divider></el-divider>
          <div id="button">
            <el-row>
              <el-col :span="1">
                <el-button type="primary" align="left" icon="el-icon-plus" class="button_margin_top button_size"
                  >物流配送信息</el-button
                >
              </el-col>
            </el-row>
          </div>
          <div class="textTips">
            <el-divider class="el-divider"></el-divider>
          </div>
          
              <el-row>
                <el-form-item label="发货省" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.deliveryProvince"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label=" 发货市" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.deliveryCity"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="发货区域" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.deliveryArea"></el-input>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="收货省" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.receivingProvince"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="收货市" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.receivingCity"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="收货区域" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.receivingArea"></el-input>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="路线编码" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.routingCode"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="运输方式" style="display:inline-block">
                    <el-col :span="22">
                      <el-select placeholder="方式" v-model="formData.transportModes" clearable>
                        <el-option v-for="item in transportMode" :label="item.name" :value="item.datavalue"></el-option>
                      </el-select>
                    </el-col>
                </el-form-item>
                <el-form-item label="车型" style="display:inline-block">
                  <el-col :span="22">
                    <el-select placeholder="车型" v-model="formData.carType" clearable>
                        <el-option v-for="item in model" :label="item.name" :value="item.datavalue"></el-option>
                      </el-select>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="车长" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.carCaptain"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="车厢温度" style="display:inline-block">
                  <el-col :span="22">
                    <el-select placeholder="车厢温度" v-model="formData.carTemperature" clearable>
                        <el-option v-for="item in carriageTemperature" :label="item.name" :value="item.datavalue"></el-option>
                      </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item label="运输状态" style="display:inline-block">
                    <el-col :span="22">
                      <el-select placeholder="运输状态" v-model="formData.transportState" clearable>
                        <el-option v-for="item in transportationStatus" :label="item.name" :value="item.datavalue"></el-option>
                      </el-select>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="发货联系人" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.deliveryContact"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="发货联系人电话" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.deliveryContactPhone"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="发货地址" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.deliveryAddress"></el-input>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="收货地址" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.receivingAddress"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="收货主体名称" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.acceptorName"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="货物重量" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.cargoWeight"></el-input>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="货物体积" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.cargoMeasurement"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="货物件数" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.cargoPieces"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="车辆牌照号" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.carNumber"></el-input>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="保险单号" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.insuranceNo"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="	保险公司名称" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.insuranceCorpName	"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="驾驶员名称" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.driverName"></el-input>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="驾驶员身份证号" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.driverIdno"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="	驾驶员手机号" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.driverPhone	"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="IP地址" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.ip"></el-input>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="MAC地址" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.mac"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="	最短要求提货时间" style="display:inline-block">
                    <el-col :span="22">
                      <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                        v-model="dataForm.minRequiredPickUpPeriod"
                        type="datetime"
                        placeholder="选择日期">
                      </el-date-picker>
                    </el-col>
                </el-form-item>
                <el-form-item label="最长要求提货时间" style="display:inline-block">
                    <el-col :span="22">
                      <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                        v-model="dataForm.maxRequiredPickUpPeriod"
                        type="datetime"
                        placeholder="选择日期">
                      </el-date-picker>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="最短预计到达时间" style="display:inline-block">
                  <el-col :span="22">
                    <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                        v-model="dataForm.minEstimatedArrivalPeriod"
                        type="datetime"
                        placeholder="选择日期">
                      </el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item label="	最长预计到达时间" style="display:inline-block">
                    <el-col :span="22">
                      <el-date-picker  value-format="yyyy-MM-dd HH:mm:ss"
                        v-model="dataForm.maxEstimatedArrivalPeriod"
                        type="datetime"
                        placeholder="选择日期">
                      </el-date-picker>
                    </el-col>
                </el-form-item>
                <el-form-item label="	收货主体类型" style="display:inline-block">
                    <el-col :span="22">
                      <el-select placeholder="类型" v-model="dataForm.acceptorType" clearable>
                        <el-option v-for="item in subjectType" :label="item.name" :value="item.sort" :key="item.sort"></el-option>
                      </el-select>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="	收货主体名称" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.acceptorName"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="	收货主体证件号" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.acceptorIdno	"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="	收货联系人" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.acceptorContact"></el-input>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="	收货联系人电话" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.acceptorContactPhone"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="	发货时间" style="display:inline-block">
                    <el-col :span="22">
                      <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                        v-model="dataForm.pickUpTime"
                        type="datetime"
                        placeholder="选择日期">
                      </el-date-picker>
                    </el-col>
                </el-form-item>
                <el-form-item label="	收货时间" style="display:inline-block">
                    <el-col :span="22">
                      <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                        v-model="dataForm.arrivalTime"
                        type="datetime"
                        placeholder="选择日期">
                      </el-date-picker>
                    </el-col>
                </el-form-item>
              </el-row>

              <el-divider></el-divider>

              <div id="ratingSetting">
                <div id="button">
                  <el-row>
                    <el-col :span="1">
                      <el-button type="primary" align="left" icon="el-icon-plus" class="button_margin_top button_size"
                        >GPS定位信息集</el-button
                      >
                    </el-col>
                  </el-row>
                </div>

                <div class="textTips">
                  <el-divider class="el-divider"></el-divider>
                </div>

                <div class="pingji_seting">
                  <el-form
                    label-position="right"
                    label-width="auto"
                  >
                    <div
                      v-for="(item, index) in formData.locateInfos"
                      :key="index"
                    >
                      <el-row>
                        <el-col :span="6">
                          <el-col :span="20">
                            <el-form-item label="当前时间" required>
                              <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                                v-model="item.currentTime"
                                type="datetime"
                                placeholder="选择日期">
                              </el-date-picker>
                            </el-form-item>
                          </el-col>
                        </el-col>
                        <el-col :span="4">
                          <el-col :span="20">
                            <el-form-item label="经度" required>
                              <el-input v-model="item.longitude"></el-input>
                            </el-form-item>
                          </el-col>
                        </el-col>
                        <el-col :span="4">
                          <el-col :span="20">
                            <el-form-item label="纬度" required>
                              <el-input v-model="item.latitude"></el-input>
                            </el-form-item>
                          </el-col>
                        </el-col>

                        <el-col :span="4" align="center">
                          <el-col :span="10">
                            <el-button
                              v-if="index + 1 == formData.locateInfos.length"
                              @click="addItem"
                              type="primary"
                              >增加</el-button
                            >
                          </el-col>
                          <el-col :span="10">
                            <el-button
                              @click="deleteItem(item, index)"
                              type="danger"
                              >删除</el-button
                            >
                          </el-col>
                        </el-col>
                      </el-row>
                    </div>
                  </el-form>
                </div>
              </div>
  </div>
</template>>
<script>
export default {
  props: {
    dataForm:{
      type:Object,
      default:()=>{
        return {
          acceptorType:null,
          minRequiredPickUpPeriod:null,
          maxRequiredPickUpPeriod:null,
          minEstimatedArrivalPeriod:null,
          maxEstimatedArrivalPeriod:null,
          pickUpTime:null,
          arrivalTime:null,
          locateInfos:[{currentTime:null,longitude:null,latitude:null}]
        }
      }
    }
  },
  data(){
    return{
      formData:{deliveryProvince:'广东省',deliveryCity:'广州市',deliveryArea:'天河区',receivingProvince:'广东省',receivingCity:'广州市',receivingArea:'荔湾区',routingCode:'XL21080262924',transportModes:'1',carType:'1',carCaptain:1.2
                ,carTemperature:'1',transportState:'1',deliveryContact:'小明',deliveryContactPhone:'17787319862',deliveryAddress:'荔湾区',receivingAddress:'天河区'
                ,acceptorName:null,cargoWeight:'15.541000',cargoMeasurement:'54.393500',cargoPieces:'1000',carNumber:'粤ADDS',insuranceNo:null,insuranceCorpName:'黑龙江省北安农垦宏发货物服务有限公司'
                ,driverName:'张三',driverIdno:'456789123454321',driverPhone:'17654281971',ip:'174.981.112',mac:'176.891.678',minRequiredPickUpPeriod:'2022-04-28 11:45:05'
                ,maxRequiredPickUpPeriod:'2022-04-28 11:45:05',minEstimatedArrivalPeriod:'2022-04-28 11:45:05',maxEstimatedArrivalPeriod:'2022-04-28 11:45:05',acceptorType:'1'
                ,acceptorName:'哈克收到',acceptorIdno:'21352466564534',acceptorContact:'李四',acceptorContactPhone:'16748910121',pickUpTime:'2022-04-28 11:45:05'
                ,arrivalTime:'2022-04-28 11:45:05',locateInfos:[{currentTime:'',longitude:'',latitude:''}]},
      subjectType:[],
      subjectDiction: {
        code: "subjectType",
      },
      transportMode:[],
      modeDiction:{
        code:'transportMode'
      },
      model:[],
      modelDiction:{
        code:"model"
      },
      carriageTemperature:[],
      temperatureDiction:{
        code:'carriageTemperature'
      },
      transportationStatus:[],
      transportationStatusDiction:{
        code:"transportationStatus"
      },
    }
  },
  watch:{
    dataForm(newVal,oldVal){
      console.log('沟子：',oldVal,newVal)
      this.formData = newVal
    }
  },
  created(){
    console.log('加载中...',this.dataForm)
    this.formData = this.dataForm ? this.dataForm : {}
  },
  mounted:function(){
    this.loadState()
  },
  methods: {
    loadState(){
      this.api.platformApi
        .findDictionariesItemList(this.stateDiction)
        .then((res) => {
          this.taskState = res.data.data;
        })
      this.api.platformApi.findDictionariesItemList(this.subjectDiction)
        .then((res)=>{
          this.subjectType = res.data.data;
        })
      this.api.platformApi.findDictionariesItemList(this.typeDiction)
        .then((res)=>{
          this.taskType = res.data.data;
        })
        this.api.platformApi.findDictionariesItemList(this.modeDiction)
        .then((res)=>{
          this.transportMode = res.data.data;
        })
        this.api.platformApi.findDictionariesItemList(this.modelDiction)
        .then((res)=>{
          this.model = res.data.data;
        })
        this.api.platformApi.findDictionariesItemList(this.temperatureDiction)
        .then((res)=>{
          this.carriageTemperature = res.data.data;
        })
        this.api.platformApi.findDictionariesItemList(this.transportationStatusDiction)
        .then((res)=>{
          this.transportationStatus = res.data.data;
        })
    },
    addItem() {
      this.formData.locateInfos.push({
        currentTime:'',
        longitude:'',
        latitude:''
      });
    },
    deleteItem(item, index) {
      if(this.formData.locateInfos.length==1){
        this.$message.error("最少留一条")
        return
      }
      this.formData.locateInfos.splice(index, 1);
      console.log(this.formData.locateInfos, "删除");
    },
  }
};
</script>>