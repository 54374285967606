<template>
  <div class="search">
    <div class="log_page_class">
      <template>
        <el-form
          :inline="true"
          :model="queryForm"
          ref="queryForm"
          class="demo-form-inline"
        >
          <el-row :gutter="24">
            <el-col :span="7" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="发票代码:" prop="stateInvoiceNo">
                  <el-input v-model="queryForm.stateInvoiceNo" placeholder="请输入查询"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="7" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="所属供应商:" prop="drawerName">
                  <el-input v-model="queryForm.drawerName" placeholder="请输入查询"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="10" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="应付时间:" prop="date">
                   <el-date-picker
                      v-model="queryForm.date"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                      >
                    </el-date-picker>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24">
          
            <el-col :span="8" align="left">
              <div>
                <el-form-item class="buttonbox">
                  <el-button
                    type="primary"
                    @click="query"
                    icon="el-icon-search"
                    >查询</el-button
                  >
                  <el-button
                    type="primary"
                    @click="reset"
                    icon="el-icon-refresh-right"
                    >重置</el-button
                  >
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </template>
    </div>
    <div style="text-align: initial; margin-bottom: 10px" class="buttonbox">
      <el-button type="primary" icon="el-icon-download"
      v-if="funArr.indexOf('导出') !== -1"
       @click="exportTable">导出</el-button>
    </div>
    <el-table
      id="table"
      :data="list"
      v-loading="loading"
      :header-cell-style="tableHeader"
      :cell-style="tableCell"
      :default-sort="{ prop: 'timestamp', order: 'descending' }"
      border
    >
    <!-- <el-table-column
      type="selection"
      width="55">
    </el-table-column> -->
      <el-table-column
        type="index"
        label="序号"
        width="50"
        show-overflow-tooltip
        align="center"
      />
      <el-table-column
        prop="stateInvoiceNo"
        label="发票代码"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="stateInvoiceSerialNo"
        label="发票号码"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="amountWithTaxStr"
        label="发票含税金额"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="taxRateStr"
        label="发票税率"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="drawerName"
        label="所属供应商"
        show-overflow-tooltip
        align="center"
      />
        <el-table-column
        prop="paidAmountStr"
        label="已支付金额"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
        <el-table-column
        prop="invDate"
        label="开票时间"
        show-overflow-tooltip
        align="center"
      />
        <el-table-column
        prop="paymentDate"
        label="应付时间"
        show-overflow-tooltip
        align="center"
      />
      <el-table-column
        prop="createTime"
        label="录入时间"
        show-overflow-tooltip
        align="center"
      />
      <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              size="medium"
              @click="particulars(scope.row)"
              v-if="funArr.indexOf('详情') !== -1"
              >详情</el-link
            >
          </template>
        </el-table-column>
    </el-table>
    <div>
      <template>
        <el-pagination
          class="sx"
          background
          @size-change="handleSizeChange"
          @current-change="pageChange"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="queryForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </template>
    </div>
  </div>
</template>
<script>

import downloadFile from "@/util/downloadFile.js";

export default {
  data() {
    return {
      funArr: [],
      loading: true,
      queryForm: {
        stateInvoiceNo: '',
        drawerName: '',
        paymentDateStart: null,
        paymentDateEnd: null,
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      // 数集合
      list: [],
    };
  },
  watch:{
    'queryForm.date'(newVal){
      console.log('新变化->',newVal)
      if(newVal){
          if(newVal.length == 0){
          this.queryForm.paymentDateStart = null;
          this.queryForm.paymentDateEnd = null;
        }else if(newVal.length == 1){
          this.queryForm.paymentDateStart = newVal[0];
          this.queryForm.paymentDateEnd = null;
        }else{
          this.queryForm.paymentDateStart = newVal[0];
          this.queryForm.paymentDateEnd = newVal[1];
        }
      }else{
        this.queryForm.paymentDateStart = null;
        this.queryForm.paymentDateEnd = null;
      }
    }
  },
  mounted() {
    this.menuPermission();
    this.loadList();
  },
  methods: {
    query(){
      this.queryForm.pageNo = 1;
      this.loadList();
    },
    async menuPermission() {
      let name = "发票查询";
      let params = {
        id: this.$getCookie("sessionId"),
        menuName: name
      }
      let response = await this.api.platformApi.menuPermission(params);

      console.log(name + "页面功能列表start---")
      console.log(response.data.data)
      console.log(name + "页面功能列表end---")
      this.funArr = response.data.data
      console.log(this.funArr)
    },
    async loadList() {
      this.loading = true;
      await this.api.paymentPlanApi.pageInvoice(this.queryForm).then(res => {
        let data = res.data;
        console.log(data);
        if(data.code != 200){
          this.$message.error(data.msg);
        }else{
          this.list = data.data.records;
          this.total = data.data.total;
        }
        this.loading = false;
      }).catch(err => {
        console.log(err);
        this.loading = false;
      })
    },
    particulars(row){
      localStorage.setItem("invoice",JSON.stringify(row));
      this.$router.push({ name: "invoiceDetails" });
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    pageChange(val) {
      this.queryForm.pageNo = val;
      this.loadList();
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNo = 1;
      this.loadList();
      console.log(`每页 ${val} 条`);
    },
    reset(){
      this.resetForm('queryForm');
      setTimeout(() => {
        this.query();
      }, 200);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 导出表格
    async exportTable(){
      console.log("导出表格");
      let flag = this;
      await this.api.paymentPlanApi.exportInvoice(this.queryForm).then(res =>{
        console.log(res);
        downloadFile.exportExcel(res);
      })
      console.log("导出成功");
    }
  },
  components: {},
};
</script>
<style scoped>
.tabbox {
  text-align: left;
}
#table {
  /* padding: 20px 20px 0px 20px; */
  background-color: rgba(29, 144, 255, 0);
}
.el-dialog {
  text-align: left;
}
>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgba(228, 228, 228, 1);
}
.el-dialog button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
.search {
  background-color: white;
  padding: 15px 20px 100px 20px;
  overflow: hidden;
}
.sx {
  float: right;
  margin-top: 15px;
}
.buttonbox button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

.dialogBox {
  text-align: left;
}
/* ------------------- */
</style>
