<template>
  <el-row type="flex" justify="center">
    <el-col :xs="12" :sm="16" :md="12">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="字典编码:" prop="code">
          <el-input v-model="ruleForm.code"></el-input>
        </el-form-item>
        <el-form-item label="字典名称:" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="描述:" prop="details">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="ruleForm.details"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>  
<script>
export default {
  props: ["addrole", "total"],
  data() {
    return {
      ruleForm: {
        id: "",
        name: "",
        code: "",
        status: "",
        details: "",
      },
      rules: {
        name: [
          { required: true, message: "字典名称不能为空", trigger: "blur" },
        ],
        code: [
          { required: true, message: "字典编码不能为空", trigger: "blur" },
        ],
        details: [
          { required: true, message: "字典描述不能为空", trigger: "blur" },
        ],
      },
    };
  },

  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    async submitForm(formName) {
      await this.$refs[formName].validate((valid) => {
        if (valid) {
          var params = {
            id: this.ruleForm.id,
            name: this.ruleForm.name,
            code: this.ruleForm.code,
            details: this.ruleForm.details,
            sessionId: this.$getCookie("sessionId"),
          };
          this.createDictionaries(params);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async createDictionaries(params) {
      let response = await this.api.platformApi.createDictionaries(params);
      if (response.data.data == true) {
        this.$message({
          message: "添加成功",
          type: "success",
        });
      } else {
        this.$message({
          message: "添加失败",
          type: "warning",
        });
      }
      this.$emit("closeDialog");
      this.resetForm("ruleForm");
    },
  },
};
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-select {
  width: 100%;
}
.el-cascader {
  width: 100%;
}
</style>