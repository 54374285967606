<template>
  <div id="orderList">
    <template>
      <div>
        <el-form :inline="true" :model="orderReq" class="demo-form-inline">
          <el-row :gutter="0">
            <el-col :span="6" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="订单编号:">
                  <el-input
                    v-model="orderReq.thirdParty"
                    class="el-select"
                    placeholder="请输入查询"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <el-form-item id="anniu">
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="query"
                  >查询</el-button
                >
                <el-button
                  type="primary"
                  @click="rest"
                  icon="el-icon-refresh-right"
                  >重置</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div style="text-align: initial; margin-bottom: 10px" class="buttonbox">
        <!-- <el-button type="primary" icon="el-icon-upload2" @click="openUpload()">导入</el-button> -->
        <el-button type="primary" icon="el-icon-plus" @click="add()">新增</el-button>
        <uploadOrder @uploadData="uploadData" ref="uploadOrder"></uploadOrder>
      </div>
      
      <el-table
        id="table"
        v-loading="loading"
        :data="ordrList"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
      <el-table-column type="index"  label="序号" align="center" />
        <el-table-column
          prop="thirdParty"
          label="订单编号"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="type"
          label="类型"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <div v-for="(item, index) in orderType" v-bind:key="index" >
              <div v-if="item.datavalue == scope.row.type">
                {{  item.name }}
              </div>
            </div>
            </template>
        </el-table-column>

        <el-table-column
          prop="clientBillNo"
          label="客户单号"
          :show-overflow-tooltip="true"
          align="center"
        />

        <el-table-column
          prop="bargainTime"
          label="下单日期"
          :show-overflow-tooltip="true"
          align="center"
        />

        <el-table-column
          prop="amount"
          label="下单金额"
          :show-overflow-tooltip="true"
          align="center"
        >
         <template slot-scope="scope">
            <span>{{accDiv(scope.row.amount , 100)}}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="bzCreateTime"
          label="创建时间"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="录入时间"
          :show-overflow-tooltip="true"
          align="center"
        />

        <el-table-column label="操作" fixed="right" >
            <template slot-scope="scope">
              <el-button type="text" size="small"  @click="detail(scope.row)" style="color: #FFAC1D">详情</el-button>
              <el-button type="text" size="small"  @click="edit(scope.row)" style="color: #FFAC1D">修改</el-button>
            </template>
          </el-table-column>

      </el-table>

      <el-pagination
        id="page"
        background
        @size-change="handleSizeChange"
        @current-change="currentChange"
        :page-sizes="[10, 15, 20]"
        :page-size="orderReq.pageSize"
        :current-page="orderReq.pageNo"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>

      <el-dialog title="保存订单" top="6vh" :visible.sync="dialogVisible" width="85%" class="roll-dialog" @close="close()">
            <el-form :model="dataForm" ref="dataForm" label-width="180px" :rules="rule"  hide-required-asterisk>
                <div id="button">
                  <el-row>
                    <el-col :span="1">
                      <el-button type="primary" align="left" icon="el-icon-plus" class="button_margin_top button_size"
                        >基本信息</el-button
                      >
                    </el-col>
                  </el-row>
                </div>
                <div class="textTips">
                  <el-divider class="el-divider"></el-divider>
                </div>
              <el-row>
                <el-form-item label="货主类型" style="display:inline-block" prop="buyerType">
                  <el-col :span="22">
                    <el-select placeholder="类型" v-model="dataForm.buyerType" clearable>
                        <el-option v-for="item in subjectType" :label="item.name" :value="item.sort" :key="item.sort"></el-option>
                      </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item label="货主证件号" style="display:inline-block" prop="buyerIdno">
                  <el-col :span="22">
                    <el-input v-model="dataForm.buyerIdno"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="货主名称" style="display:inline-block" prop="orderorName">
                  <el-col :span="22">
                    <el-input v-model="dataForm.orderorName"></el-input>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="下单金额" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="dataForm.amount"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="下单日期" style="display:inline-block">
                    <el-col :span="22">
                      <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                        v-model="dataForm.bargainTime"
                        type="datetime"
                        placeholder="选择日期">
                      </el-date-picker>
                    </el-col>
                </el-form-item>
                <el-form-item label="客户单号" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="dataForm.clientBillNo"></el-input>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="订单类型" style="display:inline-block;margin-left: -390px;">
                    <el-col :span="22">
                      <el-select placeholder="类型" v-model="dataForm.type" clearable>
                        <el-option v-for="item in orderType" :label="item.name" :value="item.datavalue" :key="item.sort"></el-option>
                      </el-select>
                    </el-col>
                </el-form-item>
                <el-form-item label="备注" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="dataForm.remark"></el-input>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row v-if="dataForm.type!='' && dataForm.type!=undefined">
                <detail :dataForm="dataForm.contentData" ref="detail"></detail>
              </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm()">确 定</el-button>
            </div>
        </el-dialog>

    </template>
    <!-- </div> -->
  </div>
</template>
<script>
import detail from './detail'
import uploadOrder from './uploadOrder'

export default {
  components:{
    detail,
    uploadOrder
  },
  data() {
    return {
      orderReq: {
        thirdParty: "",
        pageNo: 1,
        pageSize: 10,
      },
      dataForm:{},
      total: 0,
      ordrList:[],
      stateDiction: {
        code: "orderState",
      },
      typeDiction: {
        code: "orderType",
      },
      subjectDiction: {
        code: "subjectType",
      },
      subjectType:[],
      orderType:[],
      orderState:[],
      dialogVisible:false,
      save:false,
      dialogFormVisible:false,
      loading:false,
      rule: {
        buyerType: {
          required:true,
          message: '请选择货主类型',
          trigger: ['change']
        },
        buyerIdno: {
          required:true,
          message: '请输入货主证件号',
          trigger: ['change']
        },
        orderorName: {
          required:true,
          message: '请输入货主名称',
          trigger: ['change']
        }
      },
    };
  },

  created: function () {
    this.findDictionariesOrderTypeList(this.typeDiction);
    this.findDictionariesOrderStateList(this.stateDiction);
    this.findDictionariesSubjectTypeList(this.subjectDiction)
  },
  mounted: function (){
    this.loadTable()
  },
  methods: {
    query(){
      this.orderReq.pageNo = 1
      this.loadTable()
    },
    rest(){
      this.orderReq.thirdParty=''
      this.orderReq.pageNo = 1
      this.orderReq.pageSize = 10
      this.loadTable()
    },
    async loadTable(){
      let response = await this.api.orderApi.findOrderList(
        this.orderReq
      );
      response = response.data
      if(response.code==200){
        this.total = response.data.total
        this.ordrList = response.data.records
      }else{
        this.total = 0
        this.ordrList = []
      }
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    handleSizeChange(val) {
      this.orderReq.pageSize = val;
      this.loadTable()
    },
    currentChange(pageNo) {
      this.orderReq.pageNo = pageNo;
      this.loadTable()
    },
    //修改
    edit(row){
      this.$router.push({path: '/saveORder',query: {id: row.id}})
      // this.save = false 
      // this.dialogVisible = true
      // this.dataForm = row
    },
    close(){
      this.$refs['dataForm'].resetFields()
    },
    submitForm(){
      if(this.dataForm.buyerType!=undefined){
        if(this.dataForm.buyerIdno==undefined || this.dataForm.orderorName==undefined){
          this.$refs['dataForm'].validateField('buyerIdno');
          this.$refs['dataForm'].validateField('orderorName');
          return;
        }
      }else if(this.dataForm.buyerIdno!=undefined){
        if(this.dataForm.buyerType==undefined || this.dataForm.orderorName==undefined){
          this.$refs['dataForm'].validateField('buyerType');
          this.$refs['dataForm'].validateField('orderorName');
          return;
        }
      }
      this.dialogVisible = false
      if(this.dataForm.type!='' && this.dataForm.type!=undefined){
          this.dataForm.contentData = JSON.stringify(this.$refs.detail.formData);
      }
      if(!this.save){
        //修改
        this.api.orderApi.updateOrder(this.dataForm).then(res=>{
          res = res.data
          console.log(res);
            if(res.code == 200){
                this.loading = true;
                setTimeout(() => {
                  this.loading = false;
                  this.loadTable();
                  this.$message({
                    message: '修改成功',
                    type: 'success'
                  });
                },3000);
            }else{
              this.$message.error("修改失败");
            }
        })
    }else{
        //添加
        let thirdParty = parseInt(Math.random()*(999999-100000)+100000);
        this.dataForm.thirdParty = thirdParty;
        this.api.orderApi.createOrder(this.dataForm).then(res=>{
          res = res.data
          if(res.code == 200){
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
              this.loadTable();
              this.$message({
                message: '添加成功',
                type: 'success'
                });
              },3000);
          }else{
            this.$message.error("添加失败");
          }
        })
      }
    },
    //添加
    add(){
      this.$router.push({path: '/saveORder',query: {id: null}})
      // this.dataForm = {}
      // this.dialogVisible = true
      // this.save = true 
    },
    openUpload(){
      this.$refs.uploadOrder.openUpload();
    },
    async findDictionariesOrderTypeList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典", response);
				this.orderType = response.data.data;
		},
    async findDictionariesOrderStateList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典xxx", response);
				this.orderState = response.data.data;
		},
    async findDictionariesSubjectTypeList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典", response);
				this.subjectType = response.data.data;
		},
    uploadData(data){
      let uploadData = []
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        uploadData[index] = {}
        uploadData[index].thirdParty = parseInt(Math.random()*(999999-100000)+100000);;
        uploadData[index].bargainTime = element.bargainTime
        uploadData[index].clientBillNo = element.clientBillNo
        uploadData[index].buyerType = element.buyerType
        uploadData[index].buyerIdno = element.buyerIdno
        uploadData[index].orderorName = element.orderorName
        uploadData[index].amount = element.amount
        uploadData[index].remark = element.remark
        uploadData[index].contentData = {deliveryContact:element.deliveryContact,deliveryContactPhone:element.deliveryContactPhone,deliveryProvince:element.deliveryProvince,deliveryCity:element.deliveryCity,
                                        deliveryArea:element.deliveryArea,deliveryDetailAddress:element.deliveryDetailAddress,acceptorContact:element.acceptorContact,acceptorContactPhone:element.acceptorContactPhone,
                                        receivingProvince:element.receivingProvince,receivingCity:element.receivingCity,receivingArea:element.receivingArea,receivingDetailAddress:element.receivingDetailAddress,
                                        cargoName:element.cargoName,cargoPieces:element.cargoPieces,cargoWeight:element.cargoWeight,cargoMeasurement:element.cargoMeasurement,driverName:element.driverName,carNumber:element.carNumber,
                                        driverPhone:element.driverPhone,payeeName:element.payeeName,payeeIdno:element.payeeIdno,payeePhone:element.payeePhone,payeeBankNo:element.payeeBankNo,payeeBankcard:element.payeeBankcard,estimatedPickUpTime:element.estimatedPickUpTime,estimatedArrivalTime:element.estimatedArrivalTime}
        uploadData[index].contentData = JSON.stringify(uploadData[index].contentData);
      }
      let params = {
        list: uploadData
      }
      this.api.orderApi.createBatchOrder(params).then(res=>{

      })
      this.loading = true;
      setTimeout(() => {
          this.loading = false;
          this.$message({
          message: '请稍后刷新数据！',
          type: 'success'
        });
        }, 2000);
    },
    detail(row){
      this.$router.push({path: '/orderDetail',query: {id: row.id}})
    }
  },
};
</script>
<style scoped>
/* .repaylist_page_class {
  width: 1200px;
  height: 100%;
} */
#orderList {
  background-color: white;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 100px;
}
#orderList .marginCss {
  margin-top: 20px;
  margin-left: 6px;
}
#orderList .grid-anniu button {
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
  margin: 10px;
}
#anniu {
  border: 0;
}

#anniu button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
#table {
  width: 100%;
  background-color: rgba(29, 144, 255, 0);
}
#orderList .dialogBox {
  text-align: left;
}
#page {
  float: right;
  margin-top: 15px;
}
.buttonbox button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
.roll-dialog ::v-deep .el-dialog .el-dialog__body {
    padding: 3px 30px;
    overflow-y: scroll;
    height: 600px;
 }
</style>