import '@/plugins/axios';


/**
 * 查询在贷跟踪列表
 */
export const findLoanTrackingList = params => axios.post("/loantracking/loanTrackingList", params).then(data => data)


/**
 * 根据申请id和申请日志类型查询
 */
export const findByApplyAndType = params => axios.post("/applicationReview/findByApplyAndType", params).then(data => data)

/**
 * 修改申请状态
 */
export const updateStateById = params => axios.post("/applicationReview/updateStateById", params).then(data => data)

/**
 * 在贷跟踪详情还款计划
 */
export const findRepaymentPlan = params => axios.post("/loantracking/findRepaymentPlan", params).then(data => data)

/**
 * 在贷跟踪详情借款信息
 */
export const findLoaninformationRep = params => axios.post("/loantracking/findLoaninformationRep", params).then(data => data)

/**
 * 担保人确认(初审)
 */
export const guarantorConfirm = params => axios.post("/applicationReview/guarantorConfirm", params).then(data => data)

/**
 * 可出售(复审)
 */
export const salability = params => axios.post("/applicationReview/salability", params).then(data => data)

/**
 * 申请流程
 */
export const findApplyList = params => axios.post("/applicationReview/applyList", params).then(data => data)


/**
 * 查询初审流程
 */
 export const applyPraeiudiciumList = params => axios.post("/applicationReview/applyPraeiudiciumList", params).then(data => data)

 /**
 * 查询复审流程
 */
export const applyReexaminationList = params => axios.post("/applicationReview/applyReexaminationList", params).then(data => data)

/**
 * 查询放款流程
 */
 export const applyLoansList = params => axios.post("/applicationReview/applyLoansList", params).then(data => data)



/**
 * 签署合同
 */
export const sign = params => axios.post("/loan/sign", params).then(data => data)

/**
 * 放款
 */
export const loan = params => axios.post("/loan/loan", params).then(data => data)

/**
 * 初审通过
 */
export const passedThePreliminaryExamination = params => axios.post("/applicationReview/passedThePreliminaryExamination", params).then(data => data)

/**
 * 初审拒绝
 */
export const rejectionOfPreliminaryTrial = params => axios.post("/applicationReview/rejectionOfPreliminaryTrial", params).then(data => data)

/**
 * 复审通过
 */
export const reviewedAndApproved = params => axios.post("/applicationReview/reviewedAndApproved", params).then(data => data)

/**
 * 复审拒绝
 */
export const reviewRejected = params => axios.post("/applicationReview/reviewRejected", params).then(data => data)

/**
 * 根据资产id查询申请表
 */
 export const getApplyByAssetsId = params => axios.post("/apply/getApplyByAssetsId", params).then(data => data)

/**
 * 获取确权通知书合同url
 */
export const getConfirmationNoticeUrl = params => axios.post("/contract/getConfirmationNoticeUrl", params).then(data => data)

/**
 * 获取借贷合同url
 */
export const getLoanContractUrl = params => axios.post("/contract/getLoanContractUrl", params).then(data => data)

/**
 * 获取审批结果
 */
export const approvalResults = params => axios.post("/platRepayment/approvalResults", params).then(data => data)

/**
 * 根据risk查询公司信息
 */
export const findCorpAndRisk = params => axios.post("/applicationReview/findCorpAndRisk", params).then(data => data)

/**
 * 根据proofsetid查询凭证
 */
export const findByProofSet = params => axios.post("/loantracking/findByProofSet", params).then(data => data)

/**
 * 发票管理-额度使用详情
 */
export const findCreditProofAssetsUseInfoByProofId = params => axios.post("/invoice/findCreditProofAssetsUseInfoByProofId", params).then(data => data)
/**
 * 发票管理-根据id,查询发票信息(包含：税额、不含税金额...)
 */
export const findInvoiceInfoByProofId = params => axios.post("/invoice/findInvoiceInfoByProofId", params).then(data => data)
/**
 * 发票管理-导出
 */
export const downloadsExcelDownInvoice = params => exportExcel("/invoice/downloadsExcelDownInvoice", params)
/**
 * 查询明细-导出
 */
export const downloadsExcelDownTransfer = params => exportExcel1("/sysFundAccount/downloadsExcelDownTransfer", params)

/**
 * 查询苏宁明细-导出
 */
 export const downloadsExcelDown = params => exportExcel2("/sysFundAccount/downloadsExcelDown", params)


/**
 * 发票管理-动态修改Invoice根据明码id
 */
export const dynamicUpdateInvoiceById = params => axios.post("/invoice/dynamicUpdateInvoiceById", params).then(data => data)
/**
 * 发票管理-修改授信凭证信息
 */
export const updateCreditProofInfo = params => axios.post("/invoice/updateCreditProofInfo", params).then(data => data)
/**
 * 发票管理-线下支付发票(含session)
 */
export const offlinePaidInvoiceSession = params => axios.post("/invoice/offlinePaidInvoiceSession", params).then(data => data)
/**
 * 发票管理-线上支付发票(含session)
 */
export const onlinePaidInvoiceSession = params => axios.post("/invoice/onlinePaidInvoiceSession", params).then(data => data)
/**
 * 发票管理-批量支付发票(含session)
 */
export const batchOnlinePaidInvoiceSession = params => axios.post("/invoice/batchOnlinePaidInvoiceSession", params).then(data => data)

/**
 * 根据id查询申请
 */
export const findApplyById = params => axios.post("/applicationReview/findApplyById", params).then(data => data)
/**
 * 批量支付发票操作(含session0712)
 */
export const batchOnlinePaidInvoiceOperationSession = params => axios.post("/invoice/batchOnlinePaidInvoiceOperationSession", params).then(data => data)
/**
 * 批量线下支付发票操作(含session0712)
 */
export const batchOfflinePaidInvoiceOperationSession = params => axios.post("/invoice/batchOfflinePaidInvoiceOperationSession", params).then(data => data)
/**
 * 封装导出Excal文件请求
 * @param url
 * @param data
 * @returns {Promise}
 */
//api.js
export function exportExcel(url, options = {}) {
  return new Promise((resolve, reject) => {
    console.log(`${url} 请求数据，参数=>`, JSON.stringify(options))
    axios.defaults.headers['content-type'] = 'application/json;charset=UTF-8'
    axios({
      method: 'post',
      url: url, // 请求地址
      data: options, // 参数
      responseType: 'blob' // 表明返回服务器返回的数据类型
    }).then(
      response => {
        resolve(response.data)
        let blob = new Blob([response.data], {
          type: 'application/vnd.ms-excel'
        })
        console.log(blob)
        let fileName = "发票导出列表" + '.xlsx'
        if (window.navigator.msSaveOrOpenBlob) {
          // console.log(2)
          navigator.msSaveBlob(blob, fileName)
        } else {
          // console.log(3)
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          //释放内存
          window.URL.revokeObjectURL(link.href)
        }
      },
      err => {
        reject(err)
      }
    )
  })
}
export function exportExcel1(url, options = {}) {
  return new Promise((resolve, reject) => {
    console.log(`${url} 请求数据，参数=>`, JSON.stringify(options))
    axios.defaults.headers['content-type'] = 'application/json;charset=UTF-8'
    axios({
      method: 'post',
      url: url, // 请求地址
      data: options, // 参数
      responseType: 'blob' // 表明返回服务器返回的数据类型
    }).then(
      response => {
        resolve(response.data)
        let blob = new Blob([response.data], {
          type: 'application/vnd.ms-excel'
        })
        console.log(blob)
        let fileName = options.subNo+"_资金明细" + '.xlsx'
        if (window.navigator.msSaveOrOpenBlob) {
          // console.log(2)
          navigator.msSaveBlob(blob, fileName)
        } else {
          // console.log(3)
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          //释放内存
          window.URL.revokeObjectURL(link.href)
        }
      },
      err => {
        reject(err)
      }
    )
  })
}

export function exportExcel2(url, options = {}) {
  return new Promise((resolve, reject) => {
    console.log(`${url} 请求数据，参数=>`, JSON.stringify(options))
    axios.defaults.headers['content-type'] = 'application/json;charset=UTF-8'
    axios({
      method: 'post',
      url: url, // 请求地址
      data: options, // 参数
      responseType: 'blob' // 表明返回服务器返回的数据类型
    }).then(
      response => {
        resolve(response.data)
        let blob = new Blob([response.data], {
          type: 'application/vnd.ms-excel'
        })
        console.log(blob)
        let fileName = options.name+"_资金明细" + '.xlsx'
        if (window.navigator.msSaveOrOpenBlob) {
          // console.log(2)
          navigator.msSaveBlob(blob, fileName)
        } else {
          // console.log(3)
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          //释放内存
          window.URL.revokeObjectURL(link.href)
        }
      },
      err => {
        reject(err)
      }
    )
  })
}