/*
 * 接口统一集成模块
 */
import * as platformApi from './moudules/platformApi'

import * as postLoanManagementApi from './moudules/postLoanManagementApi'

import * as preLoanManagementApi from './moudules/preLoanManagementApi'

import * as paymentPlanApi from './moudules/paymentPlanApi'

import * as orderApi from './moudules/orderApi'

// 默认全部导出
export default {
	platformApi,
	postLoanManagementApi,
	preLoanManagementApi,
	paymentPlanApi,
	orderApi
}
