import '@/plugins/axios';

/**
 * 登录
 */
export const userlogin = params => axios.post("/sysUser/login", params).then(data => data)
/**
 * 清除数据
 */
export const eliminate = params => axios.post("/eliminate/eliminate", params).then(data => data.data)

//--------------------用户管理

/**
 * 开关启用
 */
export const enable = params => axios.post("/sysTenant/enable", params).then(data => data)

/**
 * 开关冻结
 */
export const frozen = params => axios.post("/sysTenant/frozen", params).then(data => data)

/**
 * 修改用户
 */
export const editUser = params => axios.post("/sysUser/edit", params).then(data => data)

/**
 * 用户管理列表
 */
export const findByUserManagement = params => axios.post("/sysUser/findByUserManagement", params).then(data => data)

/**
 * 根据用户Id获取用户信息
 * @param {*} params 
 * @returns 
 */
export const getUserInfo = params => axios.post("/sysUser/getUserInfo", params).then(data => data)

/**
 * 重置密码
 */
export const editPwd = params => axios.post("/sysTenant/editPwd", params).then(data => data)


/**
 * 查询角色(下拉框)
 */
export const findRole = params => axios.post("/sysRole/findByRole", params).then(data => data)

/**
 * 查询部门(下拉框)
 */
export const findGroup = params => axios.post("/sysUser/findByGroup", params).then(data => data)

/**
 * 查询对应用户的权限
 */
export const getMyUserPermission = params => axios.post("/sysUser/getMyUserPermission", params).then(data => data)

/**
 * 权限树状图
 */
export const permissionList = params => axios.post("/sysUser/list", params).then(data => data)

/**
 * 添加权限
 */
export const addUserPermission = params => axios.post("/sysUser/addUserPermission", params).then(data => data)

/**
 * 查询所有部门树
 */
export const groupTree = params => axios.post("/sysGroup/getGroupTree", params).then(data => data)

/**
 * 根据所选部门找对应的角色
 */
export const groupRoleTree = params => axios.post("/sysGroup/getGroupRoleTree", params).then(data => data)

/**
 * 修改用户watch
 */
export const findUserGroupRoleById = params => axios.post("/sysUser/findUserGroupRoleById", params).then(data => data)
/**
 * 添加用户
 */
export const addSysUser = params => axios.post("/sysUser/add", params).then(data => data)

//--------------------其他管理

/**
 * 查询公司档案
 */
export const listCorpProfile = params => axios.post("/quotaRefresh/listCorpProfile", params).then(data => data)

/**
 * 额度刷新（苏宁消息通知）
 */
export const orderNoticeMessage = params => axios.post("/quotaRefresh/orderNoticeMessage", params).then(data => data)

//--------------------organization

/**
 * 机构查询
 */
export const organization = params => axios.post("/sysOrganization/findByOrganizationPage", params).then(data => data)


/**
 * 修改机构开关为关闭
 */
export const organizationDisable = params => axios.post("/sysOrganization/disable", params).then(data => data)

/**
 * 修改机构开关为启用
 */
export const organizationOpen = params => axios.post("/sysOrganization/open", params).then(data => data)

/**
 * 根据id查询organization
 */
export const findOrganizationById = params => axios.post("/sysOrganization/findById", params).then(data => data)

/**
 * 修改机构
 */
export const organizationUpdate = params => axios.post("/sysOrganization/update", params).then(data => data)

/**
 * 新增机构
 */
export const organizationCreate = params => axios.post("/sysOrganization/create", params).then(data => data)

//------------------------

// -------------------channel  start 

/**
 * 获取channel列表
 */
export const channelList = params => axios.post("/channel/channelList", params).then(data => data)


/**
 * 修改channel状态
 */
export const editChannel = params => axios.post("/channel/editChannelStatus", params).then(data => data)


// -------------------channel  end

/**
 * 数据字典
 */
export const dictionariesList = params => axios.post("/sysDictionaries/DictionariesList", params).then(data => data)
/**
 * 添加字典
 */
export const createDictionaries = params => axios.post("/sysDictionaries/createDictionaries", params).then(data => data)
/**
 * 修改字典
 */
export const editDictionaries = params => axios.post("/sysDictionaries/updateDictionaries", params).then(data => data)
/**
 * 数据字典明细
 */
export const dictionariesItemList = params => axios.post("/sysDictionaries/DictionariesItemList", params).then(data => data)
/**
 * 添加数据字典明细
 */
export const createDictionariesItem = params => axios.post("/sysDictionaries/createDictionariesItem", params).then(data => data)
/**
 * 修改数据字典明细
 */
export const editDictionariesItem = params => axios.post("/sysDictionaries/updateDictionariesItem", params).then(data => data)







/**
 * 新增用户按钮点击事件
 */
 export const addNewUser = params => axios.post("/sysUser/add",params).then(data => data)

/**
 * 添加下级部门提交
 */
export const addSubordinateDepartment = params => axios.post("/sysGroup/add",params).then(data => data)

/**
 * 基础信息提交
 */
export const submitInfo = params => axios.post("/sysGroup/edit",params).then(data => data)

/**
 * 树状结构方法
 */
export const numeralStructure = params => axios.post("/sysGroup/numeralStructure",params).then(data => data)

/**
 * 树状图点击
 */
export const basicinformation = params => axios.post("/sysGroup/findById",params).then(data => data)





/**
 * 查询日志列表
 */
export const AuditLogList = params => axios.post("/sysAudit/list",params).then(data => data)


/**
 * 查询菜单列表
 */
export const MenuList = params => axios.post("/sysMenu/menulist",params).then(data => data)

/**
 * 获取页面功能列表
 */
export const menuPermission = params => axios.post("/sysMenu/menuPermission",params).then(data => data)


/**
 * 菜单排序
 */
export const editmenu = params => axios.post("/sysMenu/edit",params).then(data => data)


/**
 * 权限菜单
 */
export const PermissionMenu = params => axios.post("/sysMenu/list",params).then(data => data)



/**
 * 权限树状图
 */
export const PermissionList = params => axios.post("/sysRole/rolePermissionList",params).then(data => data)


/**
 * 修改权限日志状态
 */
export const Editflag = params => axios.post("/sysRole/editflag",params).then(data => data)


/**
 * 角色加载部门信息
 */
export const loadGroupInfo = params => axios.post("/sysGroup/allList",params).then(data => data)


/**
 * 角色列表
 */
export const RoleList = params => axios.post("/sysRole/list",params).then(data => data)


/**
 * 角色用户列表
 */
export const RoleUserList = params => axios.post("/sysRole/findRoleUser",params).then(data => data)


/**
 * 修改角色信息
 */
export const EditRole = params => axios.post("/sysRole/edit",params).then(data => data)

/**
 * 角色添加已有用户查询
 */
export const findByRoleUser = params => axios.post("/sysRole/findByRoleUser",params).then(data => data)


/**
 * 角色添加已有用户
 */
export const addaRoleUser = params => axios.post("/sysRole/addRoleUser",params).then(data => data)

/**
 * 角色查询部门树
 */
export const getGroupTree = params => axios.post("/sysGroup/getGroupTree",params).then(data => data)


/**
 * 添加角色
 */
export const CreateRole = params => axios.post("/sysRole/add",params).then(data => data)

/**
 * 角色查询部门
 */
export const getGroup = params => axios.post("/sysUser/findgroup",params).then(data => data)

/**
 * 根据角色id绑定部门
 */
export const changerole = params => axios.post("/sysUser/findGroupByrid",params).then(data => data)

/**
 * 给角色添加相应的权限
 */
export const addrolepermission = params => axios.post("/sysRole/addrolepermission",params).then(data => data)

/**
 * 查询权限
 */
export const getMyRolePermission = params => axios.post("/sysRole/getMyRolePermission",params).then(data => data)


/**
 * 查询白名单列表
 */
export const findWhiteList = params => axios.post("/white/findWhiteList",params).then(data => data)

/**
 * 查询公司信息
 */
export const findCorpAndRisk = params => axios.post("/white/findCorpAndRisk",params).then(data => data)

/**
 * 查询法人信息
 */
export const findPersonAndRisk = params => axios.post("/white/findPersonAndRisk",params).then(data => data)

/**
 * 查询数据字典信息
 */
export const findDictionariesItemList = params => axios.post("/white/findDictionariesItemList",params).then(data => data)

/**
 * 查询数据字段信息，只查启用
 */
export const findDictionariesEnableItemList = params => axios.post("/white/findDictionariesEnableItemList",params).then(data => data)
/**
 * 查询数据字典信息
 */
 export const findDictionaries = params => axios.post("/white/findDictionariesItemList",params).then(data => data)

/**
 * 查询资金流水列表
 */
 export const transferList = params => axios.post("/sysFundFlow/Transferlist",params).then(data => data)

/**
 * 查询资金账号列表-平台
 */
 export const fundAccountList = params => axios.post("/sysFundAccount/findFundAccount",params).then(data => data)

 /**
 * 查询资金账号列表-业务
 */
 export const listScfFundAccount = params => axios.post("/sysFundAccount/listScfFundAccount",params).then(data => data)

/**
 * 查询资金账号 -> 明细列表
 */
 export const transaction = params => axios.post("/sysFundAccount/findTransferLog",params).then(data => data)

/**
 * 查询资金账号 -> 打印电子回单凭证
 */
 export const printTransferVoucher = params => axios.post("/sysFundAccount/printTransferVoucher",params).then(data => data)

/**
 * 查询资金账号 -> 提现
 */
 export const paymentApplication = params => axios.post("/sysFundAccount/paymentApplication",params).then(data => data)

/**
 * 查询资金账号 -> 提现
 */
 export const findAmount = params => axios.post("/sysFundAccount/findAmountOfPayment",params).then(data => data)


/**
 * 资金账号-查询提现状态
 */
 export const findWithdrawalCreateOrder = params => axios.post("/sysFundAccount/findWithdrawalCreateOrder",params).then(data => data)

/**
 * 资金账号-修改提现状态
 */
 export const updateOrderPaymentStatus = params => axios.post("/sysFundAccount/updateOrderPaymentStatus",params).then(data => data)



/**
 * 查询资金账号 -> 提现设置
 */
 export const withdrawalSetting = params => axios.post("/sysFundAccount/withdrawalSetting",params).then(data => data)

/**
 * 查询资金账号 -> 查询开户银行
 */
 export const findBank = params => axios.post("/sysFundAccount/findBank",params).then(data => data)

/**
 * 查询资金账号 -> 查询开户支行
 */
 export const paybankinfo = params => axios.post("/sysFundAccount/paybankinfo",params).then(data => data)

 /**
 * 平台还款（一站网->盛方达->诺也、一站网、承运商）含session
 */
export const platformLoanRepaymentSession = params => axios.post("/loan/platformLoanRepaymentSession",params).then(data => data)
 /**
 * 根据资产id,查询确权使用凭证概要信息（按资产划分
 */
export const findCreditProofAssetsOutlineInfoByAssetsId = params => axios.post("/platformPay/findCreditProofAssetsOutlineInfoByAssetsId",params).then(data => data)
 /**
 * 查询平台还款列表(平台还款页面)
 */
export const findPlatRepaymentPayList = params => axios.post("/platformPay/findPlatRepaymentPayList",params).then(data => data)
 /**
 * 查询平台还款列表
 */
export const findPlatRepaymentList = params => axios.post("/platRepayment/findPlatRepaymentList",params).then(data => data)

/**
 * 根据name查询公司信息
 */
export const findNameCorpAndRisk = params => axios.post("/platRepayment/findNameCorpAndRisk",params).then(data => data)

/**
 * 查询支付金额
 */
export const findActiveRepaymentInterest = params => axios.post("/platRepayment/findActiveRepaymentInterest",params).then(data => data)

/**
 * 调用还款
 */
export const repayment = params => axios.post("/platRepayment/repayment",params).then(data => data)

/**
 * 查询资产还款计划信息
 */
export const findAssetsRepaymentInfo = params => axios.post("/platRepayment/findAssetsRepaymentInfo",params).then(data => data)

/**
 * 查询授信发票信息
 */
export const findCreditInvoiceInfo = params => axios.post("/platRepayment/findCreditInvoiceInfo",params).then(data => data)

/**
 * 应收帐及额度
 */
 export const accountsAmountInfo = params => axios.post("/platRepayment/accountsAmountInfo",params).then(data => data)

/**
 * 获取初审/复审/放款结果
 */
export const findByApplicantApplyAndType = params => axios.post("/platRepayment/findByApplicantApplyAndType",params).then(data => data)

/**
 * 资金账号-查询电子账户余额
 */
export const findAvailableBalance = params => axios.post("/platRepayment/findAvailableBalance",params).then(data => data)


/**
 * 发送短信
 */
export const sendSms = params => axios.post("/platRepayment/sendSms",params).then(data => data)

/**
 * 验证码验证
 */
export const authSendSms = params => axios.post("/platRepayment/authSendSms",params).then(data => data)



 /**
 * 调用接口
 */
export const Confluence = params => axios.post("/white/Confluence",params).then(data => data)


 /**
 * 跑授信
 */
export const runCredit = params => axios.post("/white/runCredit",params).then(data => data)


/**
 * 更新授信
 */
export const updateCredit = params => axios.post("/white/updateCredit",params).then(data => data)


 /**
 * 获取放款账户信息
 */
export const getFundorAccountInfo = params => axios.post("/sysFundAccount/getFundorAccountInfo",params).then(data => data)

/**
 * 获取支付账户信息
 */
export const getPayAccountInfo = params => axios.post("/sysFundAccount/getPayAccountInfo",params).then(data => data)

/**
 *  获取租户列表
 * @param {*} params 
 * @returns 
 */
 export const getTenantAndUserList = params => axios.post( "/sysTenant/getTenantAndUserList",params).then(data => data)

/**  updateSummaryDataAmount
 * 拿树状地址
 */ 
 export const getTreeDicArea = params => axios.post("/dicArea/getTreeDicArea", params).then(data => data)

  /**
 * 查看所有开户行
 * @param {*} params 
 * @returns 
 */
  // export const snFindBank = params => axios.post("/channel/snFindBank",params).then(data => data)
  export const snFindBank = params => axios.post("/sysTenant/snFindBank",params).then(data => data)

   /**
 * 查看所有开户支行
 * @param {*} params 
 * @returns 
 */
  // export const snFindBankBranch = params => axios.post("/channel/snFindBankBranch",params).then(data => data)
  export const snFindBankBranch = params => axios.post("/sysTenant/snFindBankBranch",params).then(data => data)

   /**
 * 添加住户
 * @param {*} params 
 * @returns 
 */
  export const addTenant = params => axios.post( "/sysTenant/addTenant",params).then(data => data)

/**
 * 修改住户的用户的状态
 * @param {*} params 
 * @returns 
 */
    export const updateTenantUserFlag = params => axios.post( "/sysTenant/updateTenantUserFlag",params).then(data => data)

/**
 *  修改时回显租户信息
 * @param {*} params 
 * @returns 
 */
 export const updateTenantFind = params => axios.post( "/sysTenant/updateTenantFind",params).then(data => data)

  /**
 *  修改租户
 * @param {*} params 
 * @returns 
 */
   export const updateTenant = params => axios.post( "/sysTenant/updateTenant",params).then(data => data)

   /**
 * 重置密码
 */
export const resetPwdNotVerify = params => axios.post("/sysTenant/resetPwdNotVerify", params).then(data => data)

/**
 * 获取权限设置信息
 * @returns 
 */
export const getAuditSettingByTenantIdAndPermission = params => axios.post("/sysAudit/getAuditSettingByTenantIdAndPermission", params).then(data => data)

/**
 * 电子回单批量下载
 */
export const batchDownload = params => batchDownloadApi("/sysFundFlow/batchDownload", params)

export function batchDownloadApi(url, options = {}) {
	console.log("电子回单批量下载开始")
  return new Promise((resolve, reject) => {
	console.log("电子回单批量下载")
    console.log(`${url} 请求数据，参数=>`, JSON.stringify(options))
    axios.defaults.headers['content-type'] = 'application/json;charset=UTF-8'
    axios({
      method: 'post',
      url: url, // 请求地址
      data: options, // 参数
      responseType: 'blob' // 表明返回服务器返回的数据类型
    }).then(
      response => {
		  console.log(11)
        resolve(response.data)
        let blob = new Blob([response.data], {
          type: 'application/zip'
        })
		 
        console.log(blob)
        let fileName = '电子回单.zip'
        if (window.navigator.msSaveOrOpenBlob) {
  
          navigator.msSaveBlob(blob, fileName)
        } else {
		
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          //释放内存
          window.URL.revokeObjectURL(link.href)
        }
      },
      err => {
		console.log("电子回单批量下载失败")
        reject(err)
      }
    )
  })
}


export const batchDownloads = params => batchDownloadApis("/sysFundFlow/batchDownloads", params)

export function batchDownloadApis(url, options = {}) {
	console.log("电子回单批量下载开始")
  return new Promise((resolve, reject) => {
	console.log("电子回单批量下载")
    console.log(`${url} 请求数据，参数=>`, JSON.stringify(options))
    axios.defaults.headers['content-type'] = 'application/json;charset=UTF-8'
    axios({
      method: 'post',
      url: url, // 请求地址
      data: options, // 参数
      responseType: 'blob' // 表明返回服务器返回的数据类型
    }).then(
      response => {
		  console.log(11)
        resolve(response.data)
        let blob = new Blob([response.data], {
          type: 'application/zip'
        })
		 
        console.log(blob)
        let fileName = '电子回单.zip'
        if (window.navigator.msSaveOrOpenBlob) {
  
          navigator.msSaveBlob(blob, fileName)
        } else {
		
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          //释放内存
          window.URL.revokeObjectURL(link.href)
        }
      },
      err => {
		console.log("电子回单批量下载失败")
        reject(err)
      }
    )
  })
}


