<template>
  <div id="loanProcessingDetails" v-loading="loading">
    <el-row>
      <el-col>
        <span>借款信息</span>
      </el-col>
    </el-row>
    <div class="table padding_class">
      <table>
        <tr>
          <td>贷款编号</td>
          <td>{{ loaninformationRep.assetsId }}</td>
        </tr>
        <tr>
          <td>申请时间</td>
          <td>{{ loaninformationRep.applicationTime }}</td>
        </tr>
        <tr>
          <td>放款时间</td>
          <td>{{ loaninformationRep.lendingTime }}</td>
        </tr>
        <tr>
          <td>到期时间</td>
          <td>{{ loaninformationRep.expectedEndDate }}</td>
        </tr>
        <tr>
          <td>贷款产品</td>
          <td>{{ loaninformationRep.productName }}</td>
        </tr>
        <tr>
          <td>贷款金额</td>
          <td>{{ loaninformationRep.loanAmount / 100 }}</td>
        </tr>
        <tr>
          <td>贷款利率</td>
          <td>{{ loaninformationRep.creditInterestRate / 10000 }}%/日</td>
        </tr>
        <tr>
          <td>手续费率</td>
          <td>{{ loaninformationRep.serviceChargeRate / 10000 }}%</td>
        </tr>
        <tr>
          <td>手续费</td>
          <td>
            {{ loaninformationRep.expectedServiceCharge / 100 }}
          </td>
        </tr>
        <tr>
          <td>贷款期限</td>
          <td>{{ loaninformationRep.repaymentInterval }}天</td>
        </tr>
        <tr>
          <td>预计利息</td>
          <td>
            {{ loaninformationRep.expectedInterest / 100 }}
          </td>
        </tr>
        <tr>
          <td>已收利息</td>
          <td>{{ loaninformationRep.interestReceived / 100 }}</td>
        </tr>
        <tr>
          <td>还款方式</td>
          <td>
            <div
              v-for="(item, index) in loanTypeDictionList"
              v-bind:key="index"
            >
              <div v-if="item.datavalue == loaninformationRep.loanType">
                {{ item.name }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>通知确权书</td>
          <!-- <td></td> -->
          <td>
            <el-button type="text"
              >查看</el-button
            >
          </td>
        </tr>
        <tr>
          <td>借款合同</td>
          <!-- <td></td> -->
          <td>
            <el-button type="text">查看</el-button>
          </td>
        </tr>
      </table>
      <div class="marginCss">
        <el-row>
          <el-col>
            <span class="leftCss">本订单可使用应收帐及额度</span>
          </el-col>
        </el-row>
      </div>
      <div>
        <el-table
          class="personTable"  
          :data="orderCollectionAndQuotaRecheckList"
          :header-cell-style="tableHeader"
          ref="OrderCollectionAndQuotaList"
          border
          >
          <el-table-column
            label="序号"
            type="index"
            width="50"
            :show-overflow-tooltip="true"
           align="center">
          </el-table-column>
          <el-table-column
            prop="thirdParty"
            label="应收帐编号"
            :show-overflow-tooltip="true"
           align="center">
          </el-table-column>
          <el-table-column
            prop="initiatorName"
            label="付款方"
            :show-overflow-tooltip="true"
           align="center">
          </el-table-column>
          <el-table-column
            prop="acceptorName"
            label="收款方"
            :show-overflow-tooltip="true"
           align="center" />
           <el-table-column
            prop="amount"
            label="付款金额"
            :show-overflow-tooltip="true"
           align="center" >
           <template slot-scope="scope">
             <span>{{ scope.row.amount / 100 }}</span>
           </template>
           </el-table-column>
           <el-table-column
            prop="lineCredit"
            label="授信额度"
            :show-overflow-tooltip="true"
           align="center" >
           <template slot-scope="scope">
             <span>{{ scope.row.lineCredit / 100 }}</span>
           </template>
           </el-table-column>
           <el-table-column
            prop="residueCredit"
            label="剩余可用额度"
            :show-overflow-tooltip="true"
           align="center" >
           <template slot-scope="scope">
             <span>{{ scope.row.residueCredit / 100 }}</span>
           </template>
           </el-table-column>
           <el-table-column
            prop="address"
            label="确权金额"
            :show-overflow-tooltip="true"
           align="center" >
           <template slot-scope="scope">
             <span>{{ scope.row.authenticLight / 100 }}</span>
          </template>
           </el-table-column>
           <el-table-column
            prop="expireDate"
            label="付款时间"
            :show-overflow-tooltip="true"
           align="center" >
           </el-table-column>
        </el-table>
      </div>

      <div class="marginCss">
        <el-row>
          <el-col>
            <span>供应商信息</span>
          </el-col>
        </el-row>
      </div>

      <div class="table padding_class">
        <table>
          <tr>
            <td>供应商企业名称</td>
            <td>{{ corp.name }}</td>
          </tr>
          <tr>
            <td>法人姓名</td>
            <td>{{ corp.legalName }}</td>
          </tr>
          <tr>
            <td>社会统一信用代码</td>
            <td>{{ corp.idno }}</td>
          </tr>
          <tr>
            <td>经营地址</td>
            <td>{{ corp.businessAddress }}</td>
          </tr>
          <tr>
            <td>收款银行账号</td>
            <td>{{ corp.branchNo }}</td>
          </tr>
          <tr>
            <td>收款银行全称</td>
            <td>{{ corp.branchName }}</td>
          </tr>
          <tr>
            <td>开户银行省、市</td>
            <td>{{ corp.provinceOperation + corp.cityOperation }}</td>
          </tr>
          <tr>
            <td>实名认证</td>
            <td>已认证</td>
          </tr>
          <tr>
            <td>电子签章</td>
            <td>已开通</td>
          </tr>
        </table>
      </div>

      <div class="marginCss">
        <el-row>
          <el-col>
            <span>法人信息</span>
          </el-col>
        </el-row>
      </div>

      <div class="table padding_class">
        <table>
          <tr>
            <td>法人姓名</td>
            <td>{{ riskPerson.name }}</td>
          </tr>
          <tr>
            <td>身份证号</td>
            <td>{{ riskPerson.idno }}</td>
          </tr>
          <tr>
            <td>手机号</td>
            <td>{{ riskPerson.mobile }}</td>
          </tr>
          <tr>
            <td>银行卡号</td>
            <td>{{ riskPerson.bankcard }}</td>
          </tr>
          <tr>
            <td>实名认证</td>
            <td>已认证</td>
          </tr>
          <tr>
            <td>电子签章</td>
            <td>已开通</td>
          </tr>
        </table>
      </div>

      <div class="marginCss">
        <el-row>
          <el-col>
            <span>审批结果</span>
          </el-col>
        </el-row>
      </div>

      <el-table
        id="personTable"
        :data="firstInstance"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
        <el-table-column
          prop="state"
          label="审核结果"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <div v-for="(item, index) in dictionList" v-bind:key="index">
              <div v-if="item.datavalue == scope.row.state">
                {{ item.name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="reviewedBy"
          label="审核人"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="auditTime"
          label="审核时间"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="auditOpinion"
          label="审核意见"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
      </el-table>

      <div class="marginCss">
        <el-row :gutter="30">
          <el-col :span="4">
            <div class="buttonCss">
              <el-button type="primary" @click="submit">确认放款</el-button>
              <el-button @click="close">关闭</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <el-dialog
      title="短信验证"
      :visible.sync="messageBox"
      width="50%"
      style="height: 100%"
    >
      <el-form
        label-width="110px"
        id="messageForm"
        ref="messageForm"
        :model="messageForm"
        :rules="rules"
      >
        <div>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
               <el-form-item label="户名:">
              <el-input v-model="messageForm.name" disabled></el-input>
            </el-form-item></div
            ></el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
               <el-form-item label="电子账户:">
              <el-input v-model="messageForm.bankNum" disabled></el-input>
            </el-form-item></div
            ></el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
               <el-form-item label="开户行">
              <el-input v-model="messageForm.branchName" disabled></el-input>
            </el-form-item></div
            ></el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
               <el-form-item label="可用余额">
              <el-input v-model="messageForm.useamt" disabled></el-input>
            </el-form-item></div
            ></el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
               <el-form-item label="本次放款金额:">
              <el-input v-model="messageForm.amuont" disabled></el-input>
            </el-form-item></div
            ></el-col>
          </el-row>

          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
               <el-form-item label="手机号:" prop="mobile">
              <el-input  v-model="messageForm.mobile" disabled></el-input>
            </el-form-item></div
            ></el-col>
            
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="8" class="margin_right"
              ><div>
                <el-form-item label="验证码:" prop="verificationCode">
              <el-input v-model="messageForm.verificationCode" ></el-input>
              
            </el-form-item></div
            ></el-col>
            <el-col :span="4" ><div><el-button type="primary" @click="sendSms">{{ timeStr }}</el-button></div></el-col>
          </el-row>
        </div>
      </el-form>
       <div id="anniu" slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">确认放款</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      messageBox: false,
      loading: false,
      messageForm: {
        mobile: "",
        verificationCode: "",
        name: "",
        bankNum: "",
        amuont: "",
        branchName: "",
        useamt: "",
      },
      loanReq: {
        assets: "",
        corp: "",
        thirdOrderNo: "",
        apply: "",
      },
      lendingParametersReq: {
        assets: "",
        apply: "",
      },
      loaninformationRep: {
        assetsId: "",
        productName: "",
        loanAmount: 0,
        repaymentInterval: 0,
        creditInterestRate: 0,
        principalAmount: 0,
        createTime: "",
        lendingTime: "",
        time: "",
        loanType: 0,
        expectedInterest: 0,
        interestReceived: 0,
        expectedServiceCharge: 0,
        serviceChargeRate: 0,
      },
      corp: {
        name: "",
        legalName: "",
        idno: "",
        businessAddress: "",
        branchNo: "",
        branchName: "",
        provinceOperation: "",
        cityOperation: "",
      },
      repaymentPlan: [],
      diction: {
        code: "applyState",
      },
      loanTypeDiction: {
        code: "loanType",
      },
      loanTypeDictionList: [],
      applyState: -1,
      riskPerson: {
        name: "",
        idno: "",
        mobile: "",
        bankcard: "",
      },
      dictionList: [],
      applyList: [],
      firstInstance: [],
      toInstance: [],
      timeStr: "获取短信验证码",
      applyId:'',
      orderCollectionAndQuotaRecheckList: [],
      rules: {
        mobile: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
        ],
        verificationCode: [
          { required: true, message: '验证码不能为空', trigger: 'blur' }
        ],
      },
    };
  },
  mounted: function () {
    let param = JSON.parse(localStorage.getItem("id"));
    console.log("接收参数", param);
    this.applyState = param.applyState;
    this.corp = param.corpInfo;
    this.applyId = param.applyId;
    console.log("corp" + JSON.stringify(this.corp));
    // this.loanReq.assets = param.id;
    // this.loanReq.corp = param.corpInfo.id;
    // this.lendingParametersReq.assets = param.id;
    this.findLoaninformationRep(param.id);
    this.findDictionariesItemList(this.diction);
    this.findLoanTypeDictionList(this.loanTypeDiction);
    // this.findByApplicantApplyAndType(param.id);
    this.approvalResults(param.id);
    this.findRerson(param.corpInfo.legal);
    this.loadOrderCollectionAndQuotaRecheck(param.id);
  },
  methods: {
    async loadOrderCollectionAndQuotaRecheck(assetsId){
      let param = {
        assetsId,
        profileId: this.corp.id,
        applyId: this.applyId
      }
      await this.api.paymentPlanApi.listOrderCollectionAndQuotaRecheck(param).then( res => {
        let data = res.data
        if(data.code == 200){
          this.orderCollectionAndQuotaRecheckList = data.data;
        }else{
          this.$message.error(data.msg);
        }
      })
    },
    //根据id查询申请
    async findApplyById(id){
      console.log("查询申请参数:",id)
      var param = {
        id: id,
      };
      let response = await this.api.postLoanManagementApi.findApplyById(param);
      if(response.data.code===200){
        if(response.data.data.state === 5){
          console.log("进入放款")
          this.loan()
          return
        }
        if(response.data.data.state === 3 || response.data.data.state === 6){
          console.log("进入签署")
          this.sign()
          return
        }
        this.loading = false;
        this.$message.success("该笔贷款已操作,请返回放款处理列表");
      }
    },

    //查询可用余额
    async findAccountBalance() {
      let response = await this.api.platformApi.getFundorAccountInfo({});
      console.log("可用金额返回结果", response.data);
      // if(response.data.data.findAccountBalance.data == null){
      //     response.data.data.findAccountBalance.data = 0;
      // }else{
      //   this.messageForm.useamt = response.data.data.useamt / 100;
      //   this.messageForm.name = response.data.data.result.name;
      //   this.messageForm.bankNum = response.data.data.bankNum;
      //   this.messageForm.branchName = response.data.data.bankName;
      // }
      this.messageForm.useamt = response.data.data.useamt / 100;
      this.messageForm.name = response.data.data.name;
      this.messageForm.bankNum = response.data.data.bankNum;
      this.messageForm.branchName = response.data.data.branchName;
    },
    //读秒
    send_code: function () {
      this.show = true;
      var _this = this;
      var i = 60;
      var timer = setInterval(function () {
        i--;
        _this.timeStr = i + "秒";
        if (i == 0) {
          clearInterval(timer);
          _this.timeStr = "重新获取";
        }
      }, 1000);
    },
    //短信取消
    cancel() {
      this.messageBox = false;
    },
     // 加载用户信息
    async loadUserInfo(){
      await this.api.platformApi.getUserInfo({}).then(res => {
        let data = res.data;
        if(data.code == 200){
          this.messageForm.mobile = data.data.mobile;
          // this.userInfo = data.data;
        }
      }).catch(err => {
        this.$message.error("用户手机号加载失败");
      })
    },
    //短信确认放款
    confirm() {
      this.$refs['messageForm'].validate((valid) => {
        if (valid) {
          this.verifySMS();
        } else {
          return false;
        }
      });
    },
    //验证短信
    async verifySMS() {
      var smsParam = {
        mobile: this.messageForm.mobile,
        verificationCode: this.messageForm.verificationCode,
      };
      let smsResponse = await this.api.platformApi.authSendSms(smsParam);
      console.log("验证验证码结果", smsResponse.data);
      if (smsResponse.data.code != 200) {
        this.$message.error("验证码错误");
        this.fullscreenLoading = false;
        return;
      }
      this.messageBox = false;
      this.loading = true
      console.log("sign"+JSON.stringify(this.loanReq))
      this.findApplyById(this.applyId)
      // if(this.applyState == 5){
      //   this.loan()
      // }else{
      //   this.sign();
      // }
    },
    //发送验证码
    async sendSms() {
      if (this.timeStr !== "获取短信验证码" && this.timeStr !== "重新获取") {
        return;
      }
      var params = {
        mobile: this.messageForm.mobile,
        smsType: 1,
      };
      console.log("sendsms====", params);
      let response = await this.api.platformApi.sendSms(params);
      console.log("验证码结果:", response);
      if (response.data.code != 200) {
        this.$message.error("验证码发送失败");
        return;
      }
      this.$message.success("验证码已发送");
      this.send_code();
    },
    getCookie: function (cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      // console.log("获取cookie,现在循环")
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        // console.log(c)
        while (c.charAt(0) === " ") c = c.substring(1);
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    submit() {
      this.messageBox = true;
      this.findAccountBalance("","");
      this.loadUserInfo();
    this.messageForm.amuont = this.loaninformationRep.loanAmount/100
    console.log("this.messageForm",this.messageForm)
    this.messageForm.verificationCode = '';
    // this.messageForm.useamt = this.accountBalance.useamt/100;
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    async findLoaninformationRep(assetsId) {
      var param = {
        assets: assetsId,
      };
      let response = await this.api.postLoanManagementApi.findLoaninformationRep(
        param
      );
      console.log("资产还款返回结果", response.data);
      if (null != response.data.data) {
        this.loaninformationRep = response.data.data;
      }
      console.log("赋值结果:", this.loaninformationRep);
    },
    async findRerson(id) {
      var idParam = {
        id: id,
      };
      let personResponse = await this.api.platformApi.findPersonAndRisk(
        idParam
      );
      if (personResponse.data.data != null) {
        this.riskPerson = {...personResponse.data.data};
      }
      console.log("法人返回结果", personResponse.data);
    },
    async findRepaymentPlan(assetsId) {
      var param = {
        id: assetsId,
      };
      let response = await this.api.postLoanManagementApi.findRepaymentPlan(
        param
      );
      console.log("还款计划返回结果", response.data);
      this.repaymentPlan = response.data.data;
    },
    async findDictionariesItemList(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("数据字典", response);
      this.dictionList = response.data.data;
    },
    async findLoanTypeDictionList(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("还款状态数据字典", response);
      this.loanTypeDictionList = response.data.data;
    },
    async findByApplicantApplyAndType(assetsId) {
      var param = {
        id: assetsId,
      };
      let response = await this.api.platformApi.findByApplicantApplyAndType(
        param
      );
      if (response.data.data == null) {
        return;
      }
      console.log("审批结果", response.data);
      this.firstInstance = response.data.data;
      this.loanReq.apply = this.firstInstance[0].apply;
      this.lendingParametersReq.apply = this.firstInstance[0].apply;
    },
    async approvalResults(assetsId) {
      var param = {
        id: assetsId,
      };
      let response = await this.api.postLoanManagementApi.approvalResults(
        param
      );
      if (response.data.data == null) {
        return;
      }
      console.log("审批结果", response.data);
      this.firstInstance = response.data.data;
    },
    async sign() {
      this.loading = true;
      var param = {
        assets: JSON.parse(localStorage.getItem("id")).id,
        reviewer: this.getCookie("sessionId"),
        corp: JSON.parse(localStorage.getItem("id")).corpInfo.id,
        apply: this.applyId,
      };
      let response = await this.api.postLoanManagementApi.sign(param);
      if(response.data.code!==200){
        this.loading = false;
        this.$message.error(response.data.msg);
        return 
      }else{
        this.loading = false;
        this.$message.success("放款处理中，请稍后查看");
        this.$router.push({ name: "loanTrackingList" });
      } 
      // if (response.data.data == true) {
      //   this.loading = false;
      //   this.$router.push({ name: "loanTrackingList" });
      // } 
      // if (response.data.data == false) {
      //   this.loading = false;
      //   this.$router.push({ name: "loanProcessingList" });
      // }
      // this.$router.go(-1);
      // console.log("=====sign=====", JSON.stringify(response.data));
      // console.log(response.data);
    },
    async loan() {
      this.loading = true;
      var param = {
        assets: JSON.parse(localStorage.getItem("id")).id,
        reviewer: this.getCookie("sessionId"),
        risk: JSON.parse(localStorage.getItem("id")).corpInfo.id,

      };
      let response = await this.api.postLoanManagementApi.loan(param);
      if(response.data.code!==200){
        this.loading = false;
        this.$message.error(response.data.msg);
        return 
      }
      if (response.data.data == true) {
        this.loading = false;
        this.$router.push({ name: "loanTrackingList" });
      }
      if (response.data.data == false) {
        this.loading = false;
        this.$router.push({ name: "loanProcessingList" });
      }
      // console.log("==========", JSON.stringify(response.data));
      // console.log(response.data);
    },
    close() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
#loanProcessingDetails {
  background-color: white;
  padding: 15px 0 100px 30px;
  text-align: left;
}
#loanProcessingDetails table,
td {
  border: 1px solid #e8e8e8;
  border-collapse: collapse;
  line-height: 35px;
  margin-top: 3px;
}
table {
  width: 90%;
}
td:nth-child(odd) {
  background: #fafafa;
  width: 13%;
  text-align: center;
  font-size: 13px;
}
td:nth-child(even) {
  text-align: left;
  color: #7c7c7c;
  font-size: 13px;
}
#personTable {
  width: 90%;
  margin-top: 15px;
  background-color: rgba(29, 144, 255, 0);
}
.personTable{
  width: 90%;
  margin-top: 15px;
  background-color: rgba(29, 144, 255, 0);
}
#loanProcessingDetails span {
  font-size: 15px;
}
#loanProcessingDetails .marginCss {
  margin-top: 35px;
}
#loanProcessingDetails .leftCss {
  margin-right: 27px;
}
#loanProcessingDetails .buttonCss {
  border-radius: 5px;
}
.buttonCss button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
#loanProcessingDetails .fuInfo {
  margin-top: 5px;
}
.padding_class {
  margin-top: 15px;
}
>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  /* padding-left: 0;
  padding-right: 0; */
}
.main_class {
  /* border: solid;
  margin: 0 auto; */
  padding-top: 5px;
}
.margin_right{
  margin-right: 10px;
}
</style>