<template>
  <div id="loanProcessingDetails">
    <div>
      <div class="textTips">
        <el-row>
          <el-col :span="4" align="left">
            <span>对账单信息</span>
          </el-col>
        </el-row>
      </div>
      <table id="table">
        <tr>
          <td>对账单编号</td>
          <td>{{ statementBill.thirdParty }}</td>
        </tr>
        <tr>
          <td>类型</td>
          <td>
            <div
                v-for="(item, index) in statementBillType"
                v-bind:key="index"
              >
                <div v-if="item.datavalue == statementBill.type">
                  {{ item.name }}
                </div>
              </div>  
          </td>
        </tr>
        <tr>  
          <td>对账用户名称</td>
          <td>{{ statementBill.riskName }}</td>
        </tr>
        <tr>  
          <td>对账单总费用</td>
          <td>{{ statementBill.generateExpense }}</td>
        </tr>
        <tr>
          <td>已结费用</td>
          <td>{{ statementBill.createTime }}</td>
        </tr>
        <tr>  
          <td>录入时间</td>
          <td>{{ statementBill.createTime }}</td>
        </tr>
        <tr>
          <td>创建时间</td>
          <td> {{statementBill.bzCreateTime}} </td>
        </tr>
      </table>
    </div>

    <div style="margin-top:20px">
      <div class="textTips">
        <el-row>
          <el-col :span="4" align="left">
            <span>包含作业单明细</span>
          </el-col>
        </el-row>
      </div>
      <el-table
        id="table"
        :data="taskList"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
        <el-table-column type="index"  label="序号" align="center" />
        <el-table-column
          prop="thirdParty"
          label="作业单编号"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="taskorName"
          label="供应方名称"
          :show-overflow-tooltip="true"
          align="center"
        />

        <el-table-column
          prop="type"
          label="类型"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <div v-for="(item, index) in taskType" v-bind:key="index" >
              <div v-if="item.datavalue == scope.row.type">
                {{  item.name }}
              </div>
            </div>
            </template>
        </el-table-column>

        <el-table-column
          prop="clientBillNo"
          label="客户单号"
          :show-overflow-tooltip="true"
          align="center"
        />

        <el-table-column
          prop="amount"
          label="金额"
          :show-overflow-tooltip="true"
          align="center"
        >
         <template slot-scope="scope">
            <span>{{accDiv(scope.row.amount , 100)}}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="state"
          label="状态"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <div v-for="(item, index) in taskState" v-bind:key="index" >
              <div v-if="item.datavalue == scope.row.state">
                {{  item.name }}
              </div>
            </div>
            </template>
        </el-table-column>

        <el-table-column
          prop="expectedEndTime"
          label="预计结束时间"
          :show-overflow-tooltip="true"
          align="center"
        />

        <el-table-column
          prop="createTime"
          label="录入时间"
          :show-overflow-tooltip="true"
          align="center"
        />
        <el-table-column
          prop="bzCreateTime"
          label="创建时间"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>

        <el-table-column label="操作" fixed="right" >
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="details(scope.row)" style="color: #FFAC1D">详情</el-button>
            </template>
          </el-table-column>

      </el-table>

    </div>

    <div style="margin-top:40px">
      <div class="textTips">
        <el-row>
          <el-col :span="4" align="left">
            <span>应付帐明细</span>
          </el-col>
        </el-row>
      </div>
        <el-table
        id="table"
        :data="payableList"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
        <el-table-column type="index"  label="序号" align="center" />
        <el-table-column
          prop="thirdParty"
          label="应付帐编号"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="initiatorName"
          label="付款方"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="acceptorName"
          label="收款方"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="amount"
          label="付款金额"
          show-overflow-tooltip
          align="center"
        >
        <template slot-scope="scope">
          {{ accDiv(scope.row.amount,100)}}
        </template>
        </el-table-column>
        <el-table-column
          prop="paidAmount"
          label="已付金额"
          show-overflow-tooltip
          align="center"
        >
        <template slot-scope="scope">
          {{ accDiv(scope.row.paidAmount,100)}}
        </template>
        </el-table-column>

        <el-table-column
          prop="paymentTime"
          label="付款时间"
          show-overflow-tooltip
          align="center"
        />
        <el-table-column
          prop="status"
          label="付款状态"
          show-overflow-tooltip
          align="center"
        >
          <template slot-scope="scope">
            <div v-for="(item, index) in paymentResultStatus" v-bind:key="index" >
              <div v-if="item.datavalue == scope.row.status">
                {{  item.name }}
              </div>
            </div>
          </template>
        </el-table-column>
        
      </el-table>
    </div>

    <el-dialog title="物流信息" top="0" width="80%" :visible.sync="detailDialogVisiable" >
      <logistics style="margin-left:100px" :formData="contentData" ref="logistics"></logistics>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detailDialogVisiable = false">取 消</el-button>
        <el-button type="primary" @click="detailDialogVisiable = false">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import logistics from '../task/logistics'
export default {
  components:{
    logistics,
  },
  data() {
    return {
      statementBill:{},
      stateDiction: {
        code: "statementBillState",
      },
      typeDiction: {
        code: "statementBillType",
      },
      subjectDiction: {
        code: "subjectType",
      },
      statusDiction: {
        code: "statementBillStatus",
      },
      taskStateDiction: {
        code: "taskState",
      },
      taskTypeDiction: {
        code: "taskType",
      },
      paymentResultStatusDiction:{
        code:'paymentResultStatus'
      },
      paymentResultStatus:[],
      taskType:[],
      taskState:[],
      statementBillStatus:[],
      subjectType:[],
      statementBillType:[],
      statementBillState:[],
      contentData:[],
      taskList:[{id:'dd9e4d0135edd6c9b0d9b1cc8ccdf810',taskorName:'上海和耀供应链管理有限公司',blockId:'',clientBillNo:'0732094258',expectedEndTime:'2022-03-11 23:38:45',type:'G545435437',amount:999,state:'1',type:'G545435437'
                ,stateDescription:'已签收',proofName:null,tenantId:'17744230bb704e05bde6a8b4fccc42da',taskor:'2de9826e821ba9a2c691a0d4b566a166'
                ,taskorThirdParty:'409281807612116992',content:null,thirdParty:'20220310001173',enabled:'1',createTime:'2022-04-28 11:45:05',taskTrackNos:[]
                ,bzCreateTime:'2022-04-28 11:45:05',provider:'c38d23b092c3f2d9fc86a160bfb3b1ba',contentData:{deliveryProvince:'广东省',deliveryCity:'广州市',deliveryArea:'天河区'
                ,receivingProvince:'广东省',receivingCity:'广州市',receivingArea:'荔湾区',routingCode:'XL21080262924',transportModes:'1',carType:'1',carCaptain:1.2
                ,carTemperature:'1',transportState:'1',deliveryContact:'小明',deliveryContactPhone:'17787319862',deliveryAddress:'荔湾区',receivingAddress:'天河区'
                ,acceptorName:null,cargoWeight:'15.541000',cargoMeasurement:'54.393500',cargoPieces:'1000',carNumber:'粤ADDS',insuranceNo:null,insuranceCorpName:'黑龙江省北安农垦宏发货物服务有限公司'
                ,driverName:'张三',driverIdno:'456789123454321',driverPhone:'17654281971',ip:'174.981.112',mac:'176.891.678',minRequiredPickUpPeriod:'2022-04-28 11:45:05'
                ,maxRequiredPickUpPeriod:'2022-04-28 11:45:05',minEstimatedArrivalPeriod:'2022-04-28 11:45:05',maxEstimatedArrivalPeriod:'2022-04-28 11:45:05',acceptorType:'1'
                ,acceptorName:'哈克收到',acceptorIdno:'21352466564534',acceptorContact:'李四',acceptorContactPhone:'16748910121',pickUpTime:'2022-04-28 11:45:05'
                ,arrivalTime:'2022-04-28 11:45:05',locateInfos:[{currentTime:'2022-04-28 11:45:05',longitude:'116.397128',latitude:'39.916527'}]}}],
      taskReq:{
        id:''
      },
      payableList:[{amount:999,paidAmount:888,status:'1'}],
      payableReq:{
        id:''
      },
      detailDialogVisiable:false,
      contentData:{}
    };
   
  },
  created(){
    this.statementBill = this.$route.query.statementBill
    this.taskReq.id = this.statementBill.id
  },
  async mounted() {
    this.loadState()
  },
  methods: {
    loadState(){
      this.api.platformApi
        .findDictionariesItemList(this.stateDiction)
        .then((res) => {
          this.statementBillState = res.data.data;
        })
      this.api.platformApi.findDictionariesItemList(this.subjectDiction)
        .then((res)=>{
          this.subjectType = res.data.data;
        })
      this.api.platformApi.findDictionariesItemList(this.typeDiction)
        .then((res)=>{
          this.statementBillType = res.data.data;
        })
      this.api.platformApi.findDictionariesItemList(this.statusDiction)
        .then((res)=>{
          this.statementBillStatus = res.data.data;
        })
      this.api.platformApi.findDictionariesItemList(this.taskStateDiction)
        .then((res)=>{
          this.taskState = res.data.data;
        })
      this.api.platformApi.findDictionariesItemList(this.taskTypeDiction)
        .then((res)=>{
          this.taskType = res.data.data;
        })
      this.api.platformApi.findDictionariesItemList(this.paymentResultStatusDiction)
        .then((res)=>{
          this.paymentResultStatus = res.data.data;
        })      
    },
    dictionary(arr,num){
      if(arr == null || arr[num] == null){
        return '';
      }
      return arr[num].name;
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0;overflow: visible;";
    },
    //加载表格数据
    loadTaskTable(){

    },
    loadPayable(){

    },
    details(row){
      this.detailDialogVisiable = true
      this.contentData = row.contentData
    }
  },
};
</script>
<style scoped>
#loanProcessingDetails {
  background-color: white;
  padding: 15px 0 100px 30px;
  text-align: left;
}
#loanProcessingDetails table,
td {
  border: 1px solid #e8e8e8;
  border-collapse: collapse;
  line-height: 35px;
  margin-top: 3px;
}
table {
  width: 90%;
}
td:nth-child(odd) {
  background: #fafafa;
  width: 13%;
  text-align: center;
  font-size: 13px;
}
td:nth-child(even) {
  text-align: left;
  color: #7c7c7c;
  font-size: 13px;
}
#personTable {
  width: 90%;
  margin-top: 15px;
  background-color: rgba(29, 144, 255, 0);
}
#loanProcessingDetails span {
  font-size: 15px;
}
#loanProcessingDetails .marginCss {
  margin-top: 35px;
}
#loanProcessingDetails .leftCss {
  margin-right: 27px;
}
#loanProcessingDetails .buttonCss {
  border-radius: 5px;
}
.buttonCss button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
#loanProcessingDetails .fuInfo {
  margin-top: 5px;
}
.padding_class {
  margin-top: 15px;
}
>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  /* padding-left: 0;
  padding-right: 0; */
}
>>> .el-table__body-wrapper{
  overflow: visible;
}
.main_class {
  /* border: solid;
  margin: 0 auto; */
  padding-top: 5px;
}
.margin_right {
  margin-right: 10px;
}
.dialogBox {
  text-align: left;
}

.overflow{
  overflow: visible;
}
.payDiv{
  position: absolute;
  margin-left: 35px;
  background-color: #FFF;
  z-index: 9;
  visibility: hidden;
  display: none;
}
.payFreight,.payFreight:hover{
  color: #BFBF00;
}
.payFreight:hover+.payDiv,.payDiv:hover{
  visibility: visible;
  display: block;
}
</style>