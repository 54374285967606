import Vue from 'vue'
import './plugins/axios'
import moment from "moment";



import App from './App.vue'
import router from './router'
import './plugins/element.js'
import calculateUtils from './util/calculateUtils';
Vue.use(calculateUtils)
import preventClick from './plugins/clickOnceAtime.js'
Vue.use(preventClick)

import Astrict from './util/astrict.js'
Vue.use(Astrict)

//时间格式化工具
moment.locale('zh-cn')
Vue.prototype.$moment = moment

import api from '@/service/api'
Vue.prototype.api = api

// router.beforeEach((from, to, next) => {
//     if (to.meta.requireAuth) { // 判断跳转的路由是否需要登录
//         if (store.state.token) { // vuex.state判断token是否存在
//             next() // 已登录
//         } else {
//             next({
//                 path: '/login',
//                 query: {redirect: to.fullPath} // 将跳转的路由path作为参数，登录成功后跳转到该路由
//             })
//         }
//     } else {
//        next()
//     }
// })

//json请求头
Vue.prototype.$jsonHeaders = {
  headers: {
     'Content-Type':'application/json;charset=utf-8'
  }
}

Vue.prototype.$setCookie = function (cname, cvalue, exdays) {
  let d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires;
}
Vue.prototype.$getCookie = function (cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  // console.log("获取cookie,现在循环")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    // console.log(c)
    while (c.charAt(0) === " ") c = c.substring(1);
    if (c.indexOf(name) !== -1) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
Vue.prototype.$clearCookie =  function (cname) {
  this.setCookie(cname, "", -1);
}
Vue.prototype.doControl =  function (arr,key) {  
  let r = arr.indexOf(key);
  return r;
}

Vue.prototype.eqbaddr = "http://47.113.200.136:8080/esign/AccountInfoDemo/";

Vue.prototype.menuPermission = function (){
    let name = "贷款初审";
		let params = {
			id: this.$getCookie("sessionId"),
			menuName: name
		}
		let response =  this.api.platformApi.menuPermission(params);

		console.log(name + "页面功能列表start---")
		console.log(response.data.data)
		console.log(name + "页面功能列表end---")
		this.funArr = response.data.data
		console.log(this.funArr)
}

Vue.config.productionTip = false
Vue.directive('enterNumber2', {
  inserted: function (el) {
    el.addEventListener("keypress",function(e){
      e = e || window.event;
      let charcode = typeof e.charCode == 'number' ? e.charCode : e.keyCode;
      let re = /\d/;
      if(charcode == 46){
        if(el.value.includes('.')){
          e.preventDefault();
        }
        return;
      }else if(!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey){
        if(e.preventDefault){
          e.preventDefault();
        }else{
            e.returnValue = false;
        }
      }
    });
  }
});
new Vue({
  router,
 
  render: h => h(App)
}).$mount('#app')
