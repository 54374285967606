<template>
  <div id="sysuserDetails">
    <div>
      <el-form label-width="150px"  ref="formData" :model="formData" :rules="rules">
        <div>
          <el-table :data="list" style="width: 100%">
            <el-table-column
              width="55"
              align="center"
              :reserve-selection="true"
            >
            </el-table-column>
            <el-table-column prop="序号" type="index" width="50">
            </el-table-column>
            <el-table-column
              prop="thirdParty"
              label="应付帐编号"
              :show-overflow-tooltip="true"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="amount"
              label="付款金额"
              :show-overflow-tooltip="true"
              align="center"
            >
            <template slot-scope="scope">
              {{ centChangeDollar(scope.row.amount)}}
            </template>
            </el-table-column>
            <el-table-column
              prop="paymentTime"
              label="付款时间"
              :show-overflow-tooltip="true"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="未支付金额"
              prop="nonPayment"
              :show-overflow-tooltip="true"
              align="center"
            >
              <template slot-scope="scope">
                {{ centChangeDollar(scope.row.nonPayment)}}
              </template>
            </el-table-column>
            <el-table-column
              label="支付金额"
              :show-overflow-tooltip="true"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.valueNonPayment"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="padding-top: 40px"></div>
        <div>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="户名:">
                  <el-input disabled v-model="formData.name"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"><div class="main_class"></div></el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="电子账户:">
                  <el-input disabled v-model="formData.bankNum"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"><div class="main_class"></div></el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="开户行:">
                  <el-input disabled v-model="formData.branchName"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"><div class="main_class"></div></el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="可用余额:">
                  <el-input disabled v-model="formData.useamt"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"><div class="main_class"></div></el-col>
          </el-row>

          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="本次支付金额:">
                  <el-input disabled :value="currentPayment"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"
              ><div class="main_class">（币种：CNY；单位：元）</div></el-col
            >
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12">
              <div>
                <el-form-item label="支付通道:" prop="channelType">
                  <el-select placeholder="请选择" v-model="formData.channelType">
                    <el-option v-for="channelType in channelArr"  :label="channelType.name" :value="channelType.datavalue" :key="channelType.datavaluex"/>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5"/>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :span="12"
              ><div>
                <el-form-item label="手机号:" prop="mobile">
                  <el-input disabled v-model="formData.mobile"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"></el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :span="12"
              ><div>
                <el-form-item label="验证码:" prop="verificationCode">
                  <el-input v-model="formData.verificationCode"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"
              ><div>
                <div class="main_buttom_class">
                  <div>
                    <el-button type="primary" @click="sendSms">{{
                      timeStr
                    }}</el-button>
                  </div>
                </div>
              </div></el-col
            >
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>

export default {
    props: {
      list:{
        type: Array,
        required:true
      },
      mobile:{
        type: String,
        default: '',
      }
    },
    data() {
      return {
        channelCode: process.env.VUE_APP_ENUM_CHANNEL,
        channel: process.env.VUE_APP_PLATFORM_CHANNEL,
        channelArr: [],
        formData: {
          name: '',
          bankNum: '',
          branchName: '',
          useamt: '',
          invoiceSurplusAmount: '',
          channelType: '',
          mobile: this.mobile,
          verificationCode: '',
        },
        timeStr: "获取短信验证码",
        rules:{
          channelType: [
             { required: true, message: '支付通道必须', trigger: 'change' }
          ],
          mobile: [
            { required: true, message: '手机号必须', trigger: 'blur' }
          ],
          verificationCode: [
            { required: true, message: '验证码必须', trigger: 'blur' }
          ]
        }
      }
    },
    async mounted(){
      await this.loadCompanyWallet();
      this.loadChannel().then(result=>this.channelArr = result);
      // this.initializeList();
    },
    deactivated(){
      this.$destroy();
    },
    
    methods: {
      initializeList(){
        for (const key in this.list) {
          let item = this.list[key];
          this.defineProperty(item,'nonPayment',(item.amount - item.paidAmount));
          this.defineProperty(item,'valueNonPayment',this.centChangeDollar(item.nonPayment));
        }
      },
      submitForm(func) {
        return this.$refs['formData'].validate(func);
      },
      async loadCompanyWallet(){
        var param = {
          channel: this.channel,
        };
        await this.api.platformApi.findYzwWallet(param).then(res => {
          let data = res.data;
          if (data.data == null || data.data == undefined) {
            this.formData.useamt = 0;
          } else {
            this.formData.useamt = data.data.findAccountBalance.data.useamt / 100;
          }
          this.formData.name = data.data.result.name;
          this.formData.bankNum = JSON.parse(data.data.result.walletComment).rel_acct_no;
          this.formData.branchName = data.data.bankName;
        });

      },
      async loadChannel(){
        let response = await this.api.platformApi.findDictionariesEnableItemList({code: this.channelCode});
        return response.data.data;
      },
      centChangeDollar(num){
        return num/100;
      },
      defineProperty(obj,propertyName,value){
        this.$set(obj,propertyName,value);
      },
      //发送验证码
      async sendSms() {
        if (this.timeStr !== "获取短信验证码" && this.timeStr !== "重新获取") {
          return;
        }
        if(this.formData.mobile == null || this.formData.mobile == ''){
          this.$message({
            message: '没有手机号，无法获取验证码',
            type: 'warning'
          });
          return;
        }
        var params = {
          mobile: this.formData.mobile,
          smsType: 1,
        };
        console.log("sendsms====", params);
        let response = await this.api.platformApi.sendSms(params);
        console.log("验证码结果:", response);
        if (response.data.code != 200) {
          this.$message.error("验证码发送失败");
          return;
        }
        this.$message.success("验证码已发送");
        this.send_code();
      },
      send_code() {
        this.show = true;
        var i = 60;
        var timer = setInterval(() => {
          i--;
          this.timeStr = i + "秒";
          if (i <= 0) {
            clearInterval(timer);
            this.timeStr = "重新获取";
          }
        }, 1000);
      },
    },
    computed:{
      currentPayment(){
        let num = new Number(0);
        console.log(this.list);
        for (const key in this.list) {
          num += new Number(this.list[key].valueNonPayment);
        }
        return num;
      }
    },
    watch:{
      list(newVal, oldVal){

      },
      deep: true,
      immediate: true
    }

}
</script>

<style scoped>
.main_buttom_class {
  padding-left: 15px;
}
</style>

