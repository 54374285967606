export default {
  /**
   * 导出表格
   * @param {*} response 这个是响应对象
   */
  exportExcel(response){
    let fileNameEncode = response.headers['content-disposition'].split('fileName=')[1];
    const link = document.createElement("a");
    let blob = new Blob([response.data], { type: "application/vnd.ms-excel"});
    link.style.display = "none";
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download",  decodeURIComponent(fileNameEncode));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}