<template>
  <div>
        <el-divider></el-divider>
              <div id="button">
                <el-row>
                  <el-col :span="1">
                    <el-button type="primary" align="left" icon="el-icon-plus" class="button_margin_top button_size"
                      >物流配送信息</el-button
                    >
                  </el-col>
                </el-row>
              </div>
              <div class="textTips">
                <el-divider class="el-divider"></el-divider>
              </div>
              
              <el-row>
                <el-form-item label="对账状态" style="display:inline-block;margin-left:-800px;">
                    <el-col :span="22">
                      <el-select placeholder="状态" v-model="formData.reconciliationStatus" clearable>
                        <el-option v-for="item in statementBillStatus" :label="item.name" :value="item.sort" :key="item.sort"></el-option>
                      </el-select>
                    </el-col>
                </el-form-item>
              </el-row>
  </div>
</template>>
<script>
export default {
  props: ['dataForm'],
  data(){
    return{
      formData:{reconciliationStatus:'1'},
      statusDiction: {
        code: "statementBillStatus",
      },
      statementBillStatus:[],
    }
  },
  watch:{
    dataForm(newVal,oldVal){
      console.log('沟子：',oldVal,newVal)
      this.formData = newVal
    }
  },
  created(){
    this.findDictionariesStatementBillStatusList(this.statusDiction)
    console.log('加载中...',this.dataForm)
    this.formData = this.dataForm ? this.dataForm : {}
  },
  mounted:function(){

  },
  methods: {
    async findDictionariesStatementBillStatusList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典", response);
				this.statementBillStatus = response.data.data;
		},

  }
};
</script>>