<template>
	<div id="sysUserList" ref="barparent">
		<el-form :inline="true" :model="formInline" class="demo-form-inline" label-position="right">
			<el-row>
				<el-col :span="6" align="left">
					<el-form-item label="性别:">
						<el-select v-model="formInline.gender" placeholder="请选择" clearable>
							<el-option v-for="item in optionsgender" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="6" align="left">
					<el-form-item label="用户名:">
						<el-input v-model="formInline.name" placeholder="用户名"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="7" align="left">
					<el-form-item label="所属角色:" prop="rol">
						<el-select v-model="formInline.rname" placeholder="请选择角色查询" clearable>
							<el-option v-for="item in roles" :key="item.id" :label="item.name" :value="item.name">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col :span="6" align="left">
					<el-form-item label="部门:" prop="dep">
						<el-select v-model="depatment" placeholder="请选择部门查询" clearable="clearable">
							<el-option v-for="item in depss" :key="item.id" :label="item.name" :value="item.name">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="6" align="left">
					<el-form-item label="账号:" prop="account" label-width="57.9px">
						<el-input ref="formInline" v-model="formInline.account" placeholder="账号"
							onkeyup="value=value.replace(/[^\w\.\/]/ig,'')">
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="6" align="left">
					<el-form-item label="联系方式:">
						<el-input v-model="formInline.mobile" placeholder="请输入手机号码查询"
							oninput="value=value.replace(/^\.+|[^\d.]/g,'')"></el-input>
					</el-form-item>
				</el-col>

				<el-col :span="5">
					<el-form-item id="anniu">
						<el-button type="primary" icon="el-icon-search" @click="onSubmit">查询</el-button>
						<el-button type="primary" @click="rest" icon="el-icon-refresh-right">重置</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>

		<el-row>
			<el-col :span="2" align="left" style="margin-bottom: 11px" class="grid-anniu">
				<el-button @click="addUser()" icon="el-icon-plus" type="primary"
					v-if="funArr.indexOf('添加用户') !== -1"
					>添加用户</el-button>
			</el-col>
		</el-row>

		<el-dialog class="dialogBox" :close-on-click-modal="false" title="添加用户" :show-close="true"
			:visible.sync="dialogVisibleadd" v-if="dialogVisibleadd">
			<add-user :add="123" ref="uservalue"></add-user>
			<span id="anniu" slot="footer" class="dialog-footer">
				<el-button type="success" @click="cancel()">取消</el-button>
				<el-button type="primary" @success="successInsertuser" @click="insertuser">添加</el-button>
			</span>
		</el-dialog>
		<el-table :data="list" :header-cell-style="tableHeader" :cell-style="tableCell" border fit v-loading="loading">
			<el-table-column prop="sss" label="编号" width="50" align="center">
				<template slot-scope="scope">
					<span>{{
            scope.$index + 1 + (queryParams.pageNo - 1) * queryParams.pageSize
          }}</span>
				</template>
			</el-table-column>
			<!-- <el-table-column prop="id" label="编号" :show-overflow-tooltip="true" align="center"></el-table-column> -->
			<el-table-column prop="account" label="账号" :show-overflow-tooltip="true" align="center"></el-table-column>
			<el-table-column prop="name" label="用户名" :show-overflow-tooltip="true" align="center"></el-table-column>
			<el-table-column sortable prop="gender" label="性别" :show-overflow-tooltip="true" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.gender == 1">男</span>
					<span v-if="scope.row.gender == 0">女</span>
				</template>
			</el-table-column>

			<el-table-column prop="mobile" label="联系方式" :show-overflow-tooltip="true" align="center" />
			<el-table-column prop="gname" label="所属部门" :show-overflow-tooltip="true" align="center" />
			<el-table-column prop="rname" label="所属角色" :show-overflow-tooltip="true" align="center" />
			<el-table-column sortable prop="flag" label="状态" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.flag == 0">使用中</span>
					<span v-if="scope.row.flag == 1">已停用</span>
				</template>
			</el-table-column>
			<el-table-column sortable prop="createtime" label="添加时间" :show-overflow-tooltip="true" align="center" />
			<el-table-column prop="rid" v-if="show" label="rid" align="center" />
			<el-table-column prop="guid" v-if="show" label="guid" align="center" />
			<el-table-column label="操作" align="center">
				<template slot-scope="scope">
					<el-link type="primary" @click="userdetails(scope.row)" style="margin: 0 5px 0 0" :underline="false"
						size="medium">详情</el-link>

					<el-link type="primary" @click="edituser(scope.row)" :underline="false" size="medium"
						style="margin: 0 5px" v-if="funArr.indexOf('修改用户') !== -1">修改</el-link>

					<el-link type="primary" @click="permissiondis(scope.row)" :underline="false" size="medium"
						style="margin: 0 5px" v-if="funArr.indexOf('设置权限') !== -1">权限</el-link>

					<el-link type="primary" @click="editpwd(scope.row)" :underline="false" size="medium"
						style="margin: 0 5px" v-if="funArr.indexOf('设置密码') !== -1">密码</el-link>

					<el-link v-if="scope.row.flag == 0 && funArr.indexOf('停用') !== -1" type="warning"
						@click="flag(scope.row)" :underline="false" size="medium" style="margin: 0 0 0 5px">停用</el-link>

					<el-link v-if="scope.row.flag == 1 && funArr.indexOf('启用') !== -1" type="success"
						@click="flag(scope.row)" :underline="false" size="medium" style="margin: 0 0 0 5px">启用</el-link>
				
				</template>
			</el-table-column>
		</el-table>

		<el-dialog id="anniu" class="dialogBox" :close-on-click-modal="false" title="用户详情"
			:visible.sync="dialogVisibledetails">
			<sysuser-details v-bind:ruleForm="details"></sysuser-details>
			<span slot="footer" class="dialog-footer">
				<el-button type="success" @click="canceldetails()">关闭</el-button>
			</span>
		</el-dialog>

		<el-dialog class="dialogBox" :close-on-click-modal="false" title="修改用户" :visible.sync="dialogVisibleedit">
			<edit-user ref="editUser" @opened="openEditUser" :user="sigleuser" @closeDialog="canceledit"></edit-user>
		</el-dialog>
		<div>
			<el-drawer v-show="true" :visible.sync="dialogVisiblepermission">
				<sysdistribution :user="userId"></sysdistribution>
				<span slot="footer" class="dialog-footer">
					<el-button type="success" @click="cancelpermission()">取消</el-button>
				</span>
			</el-drawer>
		</div>

		<el-dialog id="anniu" class="dialogBox" :close-on-click-modal="false" title="重置密码"
			:visible.sync="dialogVisiblepwd" v-if="dialogVisiblepwd">
			<edit-pwd :username="sigle" ref="pwd"></edit-pwd>
			<span slot="footer" class="dialog-footer">
				<el-button type="success" @click="cancelpwd()">取消</el-button>
				<el-button type="primary" @click="pwd">确认</el-button>
			</span>
		</el-dialog>

		<el-pagination id="page" background @size-change="handleSizeChange" @current-change="pageChange"
			:page-sizes="[10, 15, 20]" :page-size="queryParams.pageSize" :current-page="queryParams.pageNo"
			layout="total, sizes, prev, pager, next, jumper" :total="total">
		</el-pagination>
	</div>
</template>
<script>
	import addUser from "./addUser";
	import editPwd from "./editPwd";
	import editUser from "./editUser";
	import sysuserDetails from "./sysuserDetails";
	import sysdistribution from "./sysdistribution";
	//启用
	import {
		enable
	} from "@/service/moudules/platformApi";
	//停用
	import {
		frozen
	} from "@/service/moudules/platformApi";
	//用户列表
	import {
		findByUserManagement
	} from "@/service/moudules/platformApi";
	//查询角色(下拉框)
	import {
		findRole
	} from "@/service/moudules/platformApi";
	//查询部门(下拉框)
	import {
		findGroup
	} from "@/service/moudules/platformApi";
	export default {
		data() {
			return {
				funArr: [],
				flagType: {
					id: "",
				},
				userId: "",
				show: false,
				optionsgender: [{
						value: "1",
						label: "男",
					},
					{
						value: "0",
						label: "女",
					},
				],
				dialogVisibleadd: false,
				dialogVisiblepwd: false,
				dialogVisibleedit: false,
				dialogVisibledetails: false,
				dialogVisiblepermission: false,
				options: [{
						value: "",
						label: "全部状态",
					},
					{
						value: "1",
						label: "使用中",
					},
					{
						value: "0",
						label: "已停用",
					},
				],
				value: "",
				depatment: "",
				role: "",
				formInline: {
					id: "",
					flag: "",
					account: "",
					name: "",
					gender: "",
					mobile: "",
					gname: "",
					rname: "",
					salted: "",
					rid: "",
					guid: "",
				},
				total: 0,
				queryParams: {
					pageNo: 1,
					pageSize: 10,
					accountNo: "",
					role: "",
				},
				depss: [],
				list: [],
				roles: [],
				sigle: "",
				sigleuser: "",
				details: "",
				distribution: "",
				loading: false,
			};

		},
		created:function(){
		
			console.log("用户管理")
			
			this.menuPermission()

		},

		mounted: function() {

			this.getDep();
			this.loadList();
			this.getRole();

		},
		methods: {
			async menuPermission() {
				let name = "用户管理";
				let params = {
					id: this.$getCookie("sessionId"),
					menuName: name
				}
				let response = await this.api.platformApi.menuPermission(params);

				console.log(name + "页面功能列表start---")
				console.log(response.data.data)
				console.log(name + "页面功能列表end---")
				this.funArr = response.data.data
				console.log(this.funArr)
			},
			async userListApi() {
				console.log(this.formInline);
				var params = {
					id: this.formInline.id,
					account: this.formInline.account,
					name: this.formInline.name,
					gender: this.formInline.gender,
					mobile: this.formInline.mobile,
					gname: this.depatment,
					rname: this.formInline.rname,
					flag: this.formInline.flag,
					pageSize: this.queryParams.pageSize,
					pageNo: this.queryParams.pageNo,
					sessionId: this.$getCookie("sessionId"),
				};
				let response = await this.api.platformApi.findByUserManagement(params);
				this.list = response.data.data.records;

				this.total = response.data.data.total;
			},
			async groupApi() {
				let data = {};
				let response = await this.api.platformApi.findGroup(data);
				this.depss = response.data.data;
			},
			async roleApi() {
				let data = {};
				let response = await this.api.platformApi.findRole(data);
				this.roles = response.data.data;
			},
			tableHeader(column) {
				return "height:36px;background-color:#fafafa;color:#333333;padding:0";
			},
			tableCell(column) {
				return "height:40px;background-color:#fafafa;color:#000000;padding:0";
			},
			handleSizeChange(val) {
				// console.log(val)
				// this.queryParams.params=val
				this.queryParams.pageSize = val;
				this.queryParams.pageNo = 1;
				this.loadList();
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
			},

			editSysPwd: function(row) {
				this.$router.push({
					name: "editSysPwd",
					params: row,
				});
			},
			editSysUserList: function(row) {
				this.$router.push({
					name: "editSysUserList",
					params: row,
				});
			},
			permissionDistribution: function(row) {
				this.$router.push({
					name: "permissionDistribution",
					params: row,
				});
			},
			fetchByIdSysUserList: function(row) {
				this.$router.push({
					name: "fetchByIdSysUserList",
					params: row,
				});
			},
			pwd() {
				this.$refs.pwd.submitForm("ruleForm");
				this.dialogVisiblepwd = false;
				this.loadList();
			},

			loadList: function() {
				this.userListApi();
			},
			onSubmit() {
				this.queryParams.pageNo = 1;
				this.loadList();
			},
			getDep: function() {
				this.groupApi();
			},
			getRole: function() {
				this.roleApi();
			},
			pageChange(val) {
				this.queryParams.pageNo = val;
				this.loadList();
			},
			editpwd(row) {
				this.sigle = row;
				this.dialogVisiblepwd = true;
			},
			edituser(row) {
				console.log(row);
				this.sigleuser = row;
				this.dialogVisibleedit = true;
			},
			permissiondis(row) {
				this.distribution = row;
				this.userId = row.id;
				console.log(this.userId);
				this.dialogVisiblepermission = true;
			},
			userdetails(row) {
				this.details = row;
				this.dialogVisibledetails = true;
			},

			cancel() {
				this.dialogVisibleadd = false;
				this.getDep();
				this.loadList();
				this.getRole();
			},
			cancelpwd() {
				this.dialogVisiblepwd = false;
				this.getDep();
				this.loadList();
				this.getRole();
			},
			openEditUser(){
				this.$refs.editUser.resetForm();
			},
			canceledit() {
				this.$refs.editUser.resetForm();
				this.dialogVisibleedit = false;
				this.getDep();
				this.loadList();
				this.getRole();
			},
			canceldetails() {
				this.dialogVisibledetails = false;
				this.getDep();
				this.loadList();
				this.getRole();
			},
			cancelpermission() {
				this.dialogVisiblepermission = false;
				this.getDep();
				this.loadList();
				this.getRole();
			},
			// del(row) {
			//   axios
			//     .get("/sysUser/delete?id=" + row.id)
			//     .then((response) => {
			//       console.log("删除成功");
			//       this.getDep();
			//       this.loadList();
			//       this.getRole();
			//     })
			//     .catch(function (error) {
			//       console.log(error);
			//     });
			// },
			addUser() {
				this.dialogVisibleadd = true;
			},
			async insertuser() {
				await this.$refs.uservalue.submitForm("ruleForm");
				if(this.$refs.uservalue.flag==true){
				
							this.getDep();

				this.getRole();
				this.dialogVisibleadd = false;
				this.loading = true;
				this.userListApi();
				this.loadList();
				this.$forceUpdate();
				setTimeout(() => {
					this.loadList();
				}, 3000);
				this.loading = false;
				}else{
					this.loadList();
					this.dialogVisibleadd = false;
					this.loading = false;
				}
				
		
			},
			successInsertuser() {
				this.dialogVisibleadd = false;
				this.loadList();
				this.$forceUpdate();
			},
			rest() {
				this.formInline.rname = "";
				this.depatment = "";
				this.formInline.gender = "";
				this.formInline.gender = "";
				this.formInline.mobile = "";
				this.formInline.name = "";
				this.formInline.account = "";
				this.formInline.flag = "";
				this.onSubmit();
			},
			flag(row) {
				this.flagType.id = row.id;
				if (row.flag == 1) {
					this.userEnableApi();
				} else {
					this.userFrozenApi();
				}
			},

			async userEnableApi() {
				let response = await this.api.platformApi.enable(this.flagType);
				this.loadList();
			},
			async userFrozenApi() {
				let response = await this.api.platformApi.frozen(this.flagType);
				this.loadList();
			},
			setCookie: function(cname, cvalue, exdays) {
				let d = new Date();
				d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
				let expires = "expires=" + d.toUTCString();
				document.cookie = cname + "=" + cvalue + "; " + expires;
			},
			getCookie: function(cname) {
				let name = cname + "=";
				let ca = document.cookie.split(";");
				// console.log("获取cookie,现在循环")
				for (let i = 0; i < ca.length; i++) {
					let c = ca[i];
					// console.log(c)
					while (c.charAt(0) === " ") c = c.substring(1);
					if (c.indexOf(name) !== -1) {
						return c.substring(name.length, c.length);
					}
				}
				return "";
			},
			clearCookie: function(cname) {
				this.setCookie(cname, "", -1);
			},
		},
		components: {
			addUser,
			editPwd,
			editUser,
			sysuserDetails,
			sysdistribution,
		},

	};
</script>
<style scoped>
	.userlist_page_class {
		width: 1200px;
		height: 100%;
		/* margin: 0 auto; */
	}

	#sysUserList .grid-anniu button {
		padding: 0px;
		width: 120px;
		height: 32px;
		border-radius: 5px;
	}

	#anniu {
		border: 0;
	}

	#anniu button {
		margin-right: 15px;
		padding: 0px;
		width: 80px;
		height: 32px;
		border-radius: 5px;
	}

	#sysUserList .dialogBox {
		text-align: left;
	}

	#page {
		float: right;
		margin-top: 15px;
	}

	#sysUserList {
		overflow: hidden;
		background-color: white;
		padding: 15px 20px 100px;
	}

	#sysUserList .el-input>input {
		height: 32px;
		border-radius: 5px;
		border: 2px solid #e8e8e8;
		width: 200px;
	}

	>>>.el-dialog__body {
		border-top: 1px solid rgba(228, 228, 228, 1);
		border-bottom: 1px solid rgb(228, 228, 228, 1);
		padding-left: 0;
		padding-right: 0;
	}

	#sysUserList .el-select {
		width: 210px;
	}

	>>>.el-drawer.rtl {
		overflow: scroll;
	}
</style>
